import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface INewConfigData {
  allowedDomains: string[];
  dappId: string;
}

export default function useEditDappConfigMutation() {
  const queryClient = useQueryClient();
  // options?: UseQueryOptions<Dapp[], Error, TData>
  return useMutation(
    async ({ dappId, allowedDomains }: INewConfigData) => {
      try {
        const result = await api.post(`/dapp/${dappId}/dapp-config`, {
          allowedDomains,
        });
        return result;
      } catch (err: any) {
        throw new Error(err.response.data.error || err.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["allowedDomains"]);
      },
    }
  );
}
