import { businessCategoryTypes } from "components/inputs/MutliSelect";
import useUserQuery from "hooks/useUserQuery";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const Onboarding: React.FC = () => {
  const location = useLocation();
  const [name, setName] = useState("");
  const [businessCategory, setBusinessCategory] = useState([
    businessCategoryTypes[0],
  ]);
  const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
  const [desc, setDesc] = useState("");
  const userQuery = useUserQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  // redirect logic
  if (userQuery.data) {
    if (userQuery.data?.isOnboarded) {
      return <Navigate to="/" replace state={{ from: location }} />;
    }
  } else {
    return <Navigate to="/auth/login" replace state={{ from: location }} />;
  }

  return (
    <main className="flex flex-grow justify-center">
      <form className="flex flex-col max-w-sm flex-grow mx-3 pt-24 gap-5">
        {step === 1 && (
          <Step1
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            setName={setName}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <Step2
            category={businessCategory}
            setCategory={setBusinessCategory}
            desc={desc}
            setDesc={setDesc}
            setStep={setStep}
          />
        )}
        {step === 3 && (
          <Step3
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            setStep={setStep}
            businessCategory={businessCategory}
            desc={desc}
          />
        )}
        {step === 4 && <Step4 name={name} />}
      </form>
    </main>
  );
};

export default Onboarding;
