import React from "react";
import { ButtonProps } from "react-html-props";
import { twMerge } from "tailwind-merge";

export interface PrimaryButtonProps extends ButtonProps {
  className?: string;
  disabled?: boolean;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  disabled,
  className,
  onClick,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        "rounded-md bg-biconomy-orange text-white flex justify-center items-center border-2 border-biconomy-orange",
        className
      )}
      onClick={(e) => {
        if (!disabled && onClick) onClick(e);
      }}
      disabled={disabled}
      {...props}
    >
      {disabled && (
        <div
          style={{ borderTopColor: "transparent" }}
          className="w-6 h-6 border-4 border-grey-400 border-dashed rounded-full animate-spin mr-2"
        ></div>
      )}
      {children}
    </button>
  );
};

export default PrimaryButton;
