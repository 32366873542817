import React, { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface ITextInputProps extends HTMLAttributes<HTMLInputElement> {
  className?: string;
  value?: string;
  disabled?: boolean;
  registry?: any;
  type?: string;
}

const TextInput: React.FunctionComponent<ITextInputProps> = ({
  className,
  disabled,
  registry,
  ...props
}) => {
  return (
    <input
      className={twMerge(
        "border-light-border border-2 text-base font-light rounded-md px-4 py-3 placeholder:text-foreground-light",
        className
      )}
      {...registry}
      {...props}
    />
  );
};

export default TextInput;
