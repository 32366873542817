import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";

interface ISelectProps {
  buttonClassName?: string;
  disabled?: boolean;
  options: Option[];
  selected: Option;
  onChange: (newSelection: Option) => any;
}

export type Option = {
  title?: string;
  disabled?: boolean;
  [key: string]: any;
};

export const Select: React.FC<ISelectProps> = ({
  options,
  selected,
  buttonClassName,
  disabled,
  onChange,
}) => {
  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled}>
      <div className="relative mt-1">
        <Listbox.Button
          className={twMerge(
            "relative w-full py-3 pl-4 pr-10 text-left text-foreground font-medium bg-white rounded-md border-2 border-light-border focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500",
            buttonClassName,
            disabled && "bg-disabled text-foreground-light/50"
          )}
        >
          <span className="block truncate capitalize">
            {selected?.title || selected?.name}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className={twMerge(
                "w-5 h-5 text-gray-400",
                disabled && "text-foreground-light/50"
              )}
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 z-10 focus:outline-none sm:text-sm">
            {options.map((option, optionIdx) => (
              <Listbox.Option
                key={optionIdx}
                disabled={option.disabled}
                className={({ active, disabled }) =>
                  `${
                    active
                      ? "text-amber-900 bg-amber-100"
                      : disabled
                      ? "bg-gray-100"
                      : "text-gray-900"
                  }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                }
                value={option}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`${
                        selected ? "font-medium" : "font-normal"
                      } block truncate capitalize`}
                    >
                      {option?.title || option?.name}
                    </span>
                    {selected ? (
                      <span
                        className={`${
                          active ? "text-amber-600" : "text-amber-600"
                        }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Select;
