import React from "react";
import { getIcon } from "assets/icons/networks";
import { Link } from "react-router-dom";

import {
  DataCardBody,
  DataCardHeaderContainer,
  LabelledDataField,
  LabelledDataFieldContainer,
} from "pages/home/components/DataCard/index";
import { getNetworkDetail } from "utils/allSupportedNetwork";
import PrimaryButton from "components/buttons/PrimaryButton";
import { AiOutlineDelete } from "react-icons/ai";
import { Tooltip } from "components/Tooltip";

interface IDappSummaryProps {
  dappId: string;
  dappName: string;
  networkId: string;
  gasTankBalance: number;
  gasTankDepletionRate: number;
  oustandingBalance: number;
  transactionCountThisMonth: number;
  transactionCountInTotal: number;
  setDeleteDappId: React.Dispatch<React.SetStateAction<string>>;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

const DappSummary: React.FC<IDappSummaryProps> = (props) => {
  return (
    <>
      <DataCardBody isHoverEffectEnabled>
        <DataCardHeaderContainer className="flex justify-between">
          <Link to={`/dapps/${props.dappId}`}>
            <div className="text-biconomy-orange text-2xl truncate w-36">
              {props.dappName}
            </div>
          </Link>
          <div className="flex justify-between">
            <div className="mr-2 w-12 h-12 rounded-md bg-grey flex items-center justify-center rounded-tr-[1.2rem] border-2 border-biconomy-orange">
              <Tooltip message={getNetworkDetail(props.networkId, "title")}>
                <img src={getIcon(props.networkId)} alt="network" />
              </Tooltip>
            </div>
            <PrimaryButton
              className="w-12 h-12 rounded-md bg-grey flex items-center justify-center rounded-tl-[1.2rem]"
              onClick={() => {
                props.setDeleteDappId(props.dappId);
                props.setModalState(true);
              }}
            >
              <AiOutlineDelete className="mx-auto text-black" />
            </PrimaryButton>
          </div>
        </DataCardHeaderContainer>
        <Link to={`/dapps/${props.dappId}`} className="w-full">
          <LabelledDataFieldContainer>
            <LabelledDataField
              label="Txns this month"
              data={props.transactionCountThisMonth ? props.transactionCountThisMonth : 0}
            />
          </LabelledDataFieldContainer>
          <LabelledDataFieldContainer>
            <LabelledDataField
              label="Txns in total"
              data={props.transactionCountInTotal}
            />
          </LabelledDataFieldContainer>
          <LabelledDataFieldContainer>
            <LabelledDataField
              label="Gas Balance"
              data={`${props.gasTankBalance ? props.gasTankBalance.toFixed(3) : 0} ${getNetworkDetail(
                props.networkId,
                "currency"
              )}`}
            />
          </LabelledDataFieldContainer>
          <LabelledDataFieldContainer>
            <div className="flex flex-col gap-1">
              <span className="mt-2 flex text-foreground text-xs font-semibold">
                <Tooltip message="Gas Tank Depletion Rate">
                  GDR
                  <img src="/icons/info.svg" className="w-3" alt="" />
                </Tooltip>
              </span>
              <span className="text-3xl font-extralight tracking-tight">
                {`${props.gasTankDepletionRate ? props.gasTankDepletionRate.toFixed(3) : 0} ${getNetworkDetail(
                  props.networkId,
                  "currency"
                )} / hr`}
              </span>
            </div>
          </LabelledDataFieldContainer>
        </Link>
      </DataCardBody>
    </>
  );
};

export default DappSummary;
