import React from "react";
import { HiArrowRight } from "react-icons/hi";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";

import PrimaryButton from "components/buttons/PrimaryButton";
import Stepper from "./components/Stepper";
import { useNavigate } from "react-router-dom";

interface IStepProps {
  name: string;
}

const Step: React.FunctionComponent<IStepProps> = ({ name }) => {
  const navigate = useNavigate();

  return (
    <>
      <img src={BiconomyWordmark} className="w-[50%] mx-auto" alt="" />
      <Stepper step={4} />
      <div className="flex justify-between items-baseline mb-10">
        <h1 className="font-semibold text-2xl mx-auto text-foreground">
          Congragulations, {name}!
        </h1>
      </div>
      <div className="text-[144px] flex items-center justify-center">🎉</div>
      <p className="text-center text-foreground font-medium">
        You are all set to register your first dApp
      </p>
      <PrimaryButton className="w-full py-4 mt-2" onClick={() => navigate("/")}>
        <HiArrowRight className="w-5 h-auto" />
      </PrimaryButton>
    </>
  );
};

export default Step;
