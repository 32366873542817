import React from "react";

import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Auth from "pages/auth";
import Onboarding from "pages/onboarding";
import Home from "pages/home";
import AppProviders from "context";

import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import Verify from "pages/verify/Index";
import Email from "pages/verify/email";
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProviders>
        <BrowserRouter>
          <Routes>
            <Route path="/auth/*" element={<Auth />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route
              path="/onboarding/*"
              element={<Navigate to="/onboarding" replace />}
            />
            <Route path="/verify" element={<Verify />} />
            <Route path="/verify/email" element={<Email />} />
            <Route path="/*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </AppProviders>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
