import { useQuery, UseQueryOptions } from "react-query";
import api from "api/axios";

export type Dapp = {
  dappId: string;
  networkId: string;
  dappName: string;
  apiKey: string;
  apiKeyPrefix: string;
  // consistency problem
  // in dapp list, a string is returned
  // but in dapp details, a timestamp is returned
  createdOn: string;
  dappLimitStatus: boolean;
  dappLimit?: Limit;
  userLimitStatus: boolean;
  userLimit?: Limit;
  apiLimitStatus: boolean;
  apiLimit?: Limit;
  gasThreshold: number;
  effectiveBalance: number;
  lastOutstandingBalance: number;
  fundingKey: number;
  gasTankBalance: number;
  gasTankDepletionRate: number;
  oustandingBalance: number;
  transactionCountThisMonth: number;
  transactionCountInTotal: number;
};

export enum LimitType {
  NativeToken,
  NumberOfTransactions,
}

export type Limit = {
  type: 0 | 1;
  value: number;
  durationValue: number;
  durationUnit: string;
  limitStartTime: number;
  limitDurationInMs: number;
};

export default function useDappsListQuery<TData = Dapp[]>(
  options?: UseQueryOptions<Dapp[], Error, TData>
) {
  return useQuery<Dapp[], Error, TData>(
    "dapps",
    async () => {
      try {
        const dappsListResponse = await api.get("/dapp/");
        if (!dappsListResponse.data?.data?.dapps) return [];

        const dappsList: Dapp[] = dappsListResponse.data?.data.dapps.map(
          (dapp: any) => ({
            ...dapp,
            dappId: dapp._id,
            dappLimitStatus: !!dapp.dappLimitStatus,
            apiLimitStatus: !!dapp.apiLimitStatus,
            userLimitStatus: !!dapp.userLimitStatus,
          })
        );
        return dappsList;
      } catch (error: any) {
        if (error?.response?.status === 400) {
          return [];
        } else {
          throw error;
        }
      }
    },
    {
      ...options,
    }
  );
}
