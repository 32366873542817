import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface IConditionalMutation {
  dappId: string;
  apiId: string;
  conditions: any;
}

export default function useConditionalMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ apiId, dappId, conditions }: IConditionalMutation) => {
      const result = await api.post(`/meta-api/${apiId}/conditional`, {
        conditions: conditions,
        dappId: dappId,
      });
      return result.data;
    },
    {
      onSuccess: (apiId) => {
        queryClient.invalidateQueries(["metaApiDetailByDappId", apiId]);
      },
    }
  );
}
