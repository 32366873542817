import React, { useState, useEffect, useCallback } from "react";
import PrimaryButton from "components/buttons/PrimaryButton";
import Switch from "components/inputs/Switch";
import { useActionBar } from "context/ActionBar";
import useDappQuery from "hooks/useDappQuery";
import {
  DataCardBody,
  DataCardHeaderContainer,
  DataCardHeading,
  LabelledDataField,
} from "pages/home/components/DataCard";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useParams } from "react-router-dom";
import MetaTxsLimit from "../components/MetaTxsLimits";
import useMetaTxLimitMutation from "hooks/mutations/metaTxLimitMutation";
import useUserQuery from "hooks/useUserQuery";
import { getNetworkDetail } from "utils/allSupportedNetwork";

const MetaTransactionLimits: React.FC = () => {
  const user = useUserQuery();
  const { dappId } = useParams();
  const dappQuery = useDappQuery(dappId!);
  const { setCurrentNode } = useActionBar();

  function toggleMetaTransactionLimits(dappId: string) {
    setCurrentNode(
      <MetaTxsLimit dappId={dappId} userId={user.data?.userId!} />
    );
  }
  const metaTxsLimitMutation = useMetaTxLimitMutation();

  const [dappLimitStatus, setDappLimitStatus] = useState(false);
  const [userLimitStatus, setUserLimitStatus] = useState(false);
  const [apiLimitStatus, setApiLimitStatus] = useState(false);

  useEffect(() => {
    if (dappQuery.data?.dappLimitStatus) {
      setDappLimitStatus(dappQuery.data?.dappLimitStatus);
    }
    if (dappQuery.data?.userLimitStatus) {
      setUserLimitStatus(dappQuery.data?.userLimitStatus);
    }
    if (dappQuery.data?.apiLimitStatus) {
      setApiLimitStatus(dappQuery.data?.apiLimitStatus);
    }
  }, [dappQuery.data]);

  const toggleMetaTxsLimit = useCallback(
    async (entityType: string, limitStatus: boolean) => {
      await metaTxsLimitMutation.mutateAsync({
        userId: user.data?.userId!,
        dappId: dappId!,
        entityType: entityType,
        durationValue: 0,
        durationUnit: "",
        value: 0,
        limitDurationInMs: 0,
        limitStartTime: 0,
        type: 0,
        limitStatus: limitStatus,
        toggle: true,
      });
    },
    [metaTxsLimitMutation, user.data, dappId]
  );

  return (
    <DataCardBody className="bg-black/5">
      <DataCardHeaderContainer sepratorClassName="bg-black/10">
        <DataCardHeading
          title="Meta Transaction Limits"
          className=" text-black"
        />
        <div>
          <PrimaryButton
            className="bg-white px-4 py-2 text-black text-sm flex gap-2 font-medium"
            onClick={() => toggleMetaTransactionLimits(dappId!)}
          >
            <span>Edit</span>
            <MdOutlineModeEditOutline className="h-4 w-auto" />
          </PrimaryButton>
        </div>
      </DataCardHeaderContainer>

      <div className="grid grid-cols-[auto_auto_1fr] gap-y-10 gap-x-10">
        {dappQuery.data?.dappLimit && (
          <>
            <LabelledDataField
              label="Dapp Limit"
              data={`${dappQuery.data?.dappLimit?.value} ${
                dappQuery.data?.dappLimit?.type === 0
                  ? getNetworkDetail(dappQuery.data?.networkId!, "currency")
                  : "Txn"
              }`}
              dataClassName="text-2xl"
            />
            <LabelledDataField
              label="Time Frame"
              data={`${dappQuery.data?.dappLimit.durationValue} ${dappQuery.data?.dappLimit.durationUnit}s`}
              dataClassName="text-2xl"
            />
            <div className="ml-auto grid place-items-center">
              <Switch
                check={dappLimitStatus}
                updateCheck={() => {
                  const newStatus = !dappLimitStatus;
                  toggleMetaTxsLimit("dapp", newStatus);
                  setDappLimitStatus(newStatus);
                }}
              />
            </div>
          </>
        )}
        {dappQuery.data?.userLimit && (
          <>
            <LabelledDataField
              label="User Limit"
              data={`${dappQuery.data?.userLimit?.value} ${
                dappQuery.data?.userLimit?.type === 0
                  ? getNetworkDetail(dappQuery.data?.networkId!, "currency")
                  : "Txn"
              }`}
              dataClassName="text-2xl"
            />
            <LabelledDataField
              label="Time Frame"
              data={`${dappQuery.data?.userLimit.durationValue} ${dappQuery.data?.userLimit.durationUnit}s`}
              dataClassName="text-2xl"
            />
            <div className="ml-auto grid place-items-center">
              <Switch
                check={userLimitStatus}
                updateCheck={() => {
                  const newStatus = !userLimitStatus;
                  toggleMetaTxsLimit("user", newStatus);
                  setUserLimitStatus(newStatus);
                }}
              />
            </div>
          </>
        )}
        {dappQuery.data?.apiLimit && (
          <>
            <LabelledDataField
              label="API Limit"
              data={`${dappQuery.data?.apiLimit?.value} ${
                dappQuery.data?.apiLimit?.type === 0
                  ? getNetworkDetail(dappQuery.data?.networkId!, "currency")
                  : "Txn"
              }`}
              dataClassName="text-2xl"
            />
            <LabelledDataField
              label="Time Frame"
              data={`${dappQuery.data?.apiLimit.durationValue} ${dappQuery.data?.apiLimit.durationUnit}s`}
              dataClassName="text-2xl"
            />
            <div className="ml-auto grid place-items-center">
              <Switch
                check={apiLimitStatus}
                updateCheck={() => {
                  const newStatus = !apiLimitStatus;
                  toggleMetaTxsLimit("api", newStatus);
                  setApiLimitStatus(newStatus);
                }}
              />
            </div>
          </>
        )}
      </div>
    </DataCardBody>
  );
};

export default MetaTransactionLimits;
