import { useQuery, UseQueryOptions } from "react-query";
import api from "api/axios";

export type DappConfig = {
  allowedDomains: string[];
};

export default function useDappDappConfigsQuery<TData = DappConfig>(
  dappId: string,
  options?: UseQueryOptions<DappConfig, Error, TData>
) {
  return useQuery<DappConfig, Error, TData>(
    "allowedDomains",
    async () => {
      try {
        const res = await api.get(`/dapp/${dappId}/dapp-config`);
        // if (!res.data?.data) return [];
        return res.data?.data;
      } catch (error: any) {
        if (error?.response?.status === 400) {
          return [];
        } else {
          throw error;
        }
      }
    },
    {
      ...options,
    }
  );
}
