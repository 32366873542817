// import { useNetworkType } from "context/network-type";

// function RoundedButton({
//   active = false,
//   text,
//   handleToggle,
// }: {
//   active?: boolean;
//   text: string;
//   handleToggle: (newNetworkType: string) => void;
// }) {
//   return (
//     <button
//       className={`rounded-full h-9 w-[50%] px-5 text-[0.625rem] uppercase flex items-center justify-center ${
//         active
//           ? "text-white bg-biconomy-green"
//           : "text-foreground-lightest bg-white"
//       }`}
//       onClick={() => handleToggle(text)}
//     >
//       {text}
//     </button>
//   );
// }

function NetworkTypeToggle() {
  // const { networkType, setNetworkType } = useNetworkType();

  // function handleToggle(newNetworkType: string) {
  //   setNetworkType(newNetworkType);
  // }

  return (
    <>
      <div className="h-auto mt-[3.125rem]">
        <hr className="bg-light-border" />
        {/* <div className="h-12 w-full rounded-full border-2 border-light-border bg-white flex items-center justify-between p-1 mt-6">
          <RoundedButton
            active={networkType === "testnet"}
            text="testnet"
            handleToggle={handleToggle}
          />
          <RoundedButton
            active={networkType === "mainnet"}
            text="mainnet"
            handleToggle={handleToggle}
          />
        </div> */}
      </div>
      <div className="p-2 mt-5">
        <p className="text-sm font-light">
          Reach out to us on{" "}
          <a
            className="text-biconomy-orange"
            href="http://discord.gg/biconomy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>{" "}
        </p>
      </div>
    </>
  );
}

export default NetworkTypeToggle;
