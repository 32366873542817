import React from "react";
import { TextAreaProps } from "react-html-props";
import { twMerge } from "tailwind-merge";

export interface ITextAreaProps extends TextAreaProps {
  className?: string;
  registry?: any;
}

export const TextArea: React.FC<ITextAreaProps> = ({
  className,
  registry,
  ...props
}) => {
  return (
    <textarea
      rows={5}
      className={twMerge(
        "w-full border-light-border border-2 text-base font-light rounded-md px-4 py-3 placeholder:text-foreground-light",
        className
      )}
      {...registry}
      {...props}
    ></textarea>
  );
};

export default TextArea;
