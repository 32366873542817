import * as React from "react";
import { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface IDataCardBodyProps extends HTMLAttributes<HTMLDivElement> {
  isHoverEffectEnabled?: boolean;
}

const DataCardBody: React.FunctionComponent<IDataCardBodyProps> = ({
  className,
  isHoverEffectEnabled,
  children,
}) => {
  return (
    <div
      className={twMerge(
        "rounded-3xl bg-biconomy-orange/10  w-full flex flex-col px-7 py-5 pb-7 gap-5 relative",
        isHoverEffectEnabled &&
          "border border-white  hover:border-biconomy-orange/10 transition-all duration-150",
        className
      )}
    >
      {children}
    </div>
  );
};

export default DataCardBody;
