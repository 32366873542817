import Alert from "components/Alert";
import ExitActionBarButton from "components/buttons/ExitActionBarButton";
import PrimaryButton from "components/buttons/PrimaryButton";
import Select, { Option } from "components/inputs/Select";
import TextInput from "components/inputs/TextInput";
import useAddCustomApiMutation from "hooks/mutations/addCustomApiMutation";
import useDappSmartContractsQuery, {
  SmartContract,
} from "hooks/useDappSmartContractsQuery";
import React, { useEffect, useState } from "react";

interface IAddCustomAPIProps {
  dappId: string;
}
type MenthodType = {
  name: string;
  stateMutability: string;
  constant: boolean;
};

const AddCustomAPI: React.FC<IAddCustomAPIProps> = ({ dappId }) => {
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [smartContracts, setSmartContracts] = useState<SmartContract[]>([]);
  const [methods, setMethods] = useState<MenthodType[]>([]);
  // const metaTransaction = [{ title: "Enabled", id: "1" }];

  // fetch and update all smart contracts with abi
  const dappSmartContractsQuery = useDappSmartContractsQuery(dappId);
  useEffect(() => {
    if (dappSmartContractsQuery.data) {
      setSmartContracts(dappSmartContractsQuery.data);
    }
  }, [dappSmartContractsQuery.data]);

  // set default methods options
  const [selectedMethod, setSelectedMethod] = useState<Option>(methods[0]);
  const [selectedSmartContract, setSelectedSmartContract] = useState<Option>(
    smartContracts[0]
  );
  // const [selectedMetaTransaction, setSelectedMetaTransaction] =
  //   useState<Option>(metaTransaction[0]);

  // if smart contract is selected, exntract methods from abi
  useEffect(() => {
    if (selectedSmartContract) {
      try {
        const methods = [];
        const abi = JSON.parse(selectedSmartContract.abi);
        if (abi.length && abi.length > 0) {
          for (var i = 0; i < abi.length; i++) {
            let methodObj = {} as MenthodType;
            if (abi[i].name !== undefined && abi[i].type === "function") {
              methodObj.name = abi[i].name;
              methodObj.stateMutability = abi[i].stateMutability;
              methodObj.constant = abi[i].constant;
              methods.push(methodObj);
            }
          }
        }
        setMethods(methods);
      } catch (e: any) {
        setError("Invalid abi");
        console.error("Invalid abi", e);
      }
    }
  }, [selectedSmartContract]);

  // if method is selected, api post request to add custom api
  const addCustomApi = useAddCustomApiMutation();

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-6">
          <h1 className="text-xm font-bold">Add Custom API</h1>
          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Name</label>
            <TextInput
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Smart Contract</label>
            <Select
              options={smartContracts}
              selected={selectedSmartContract}
              onChange={(newSelectedSmartContract) =>
                setSelectedSmartContract(newSelectedSmartContract)
              }
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Method</label>
            <Select
              options={methods}
              selected={selectedMethod}
              onChange={(newSelectedMethod) =>
                setSelectedMethod(newSelectedMethod)
              }
            />
          </div>

          {/* <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Meta Transaction</label>
            <Select
              options={metaTransaction}
              disabled={true}
              selected={selectedMetaTransaction}
              onChange={(newSelectedMetaTransaction) =>
                setSelectedMetaTransaction(newSelectedMetaTransaction)
              }
            />
          </div> */}

          {error && <Alert color="error" message={error} />}
          {addCustomApi.isError && (
            <Alert
              color="error"
              message={(addCustomApi.error as Error).message}
            />
          )}
          {addCustomApi.isSuccess && (
            <Alert color="success" message="Updated Successfully" />
          )}

          <PrimaryButton
            disabled={!!addCustomApi.isLoading}
            className="py-3"
            onClick={() => {
              setError("");
              // console.log("Saving Custom API 💾");
              if (!selectedSmartContract?._id) setError("Please select a SC");
              if (!selectedMethod?.name) setError("Method is required");
              if (!selectedSmartContract?._id || !selectedMethod?.name) return;
              addCustomApi.mutate({
                dappId: dappId,
                contractId: selectedSmartContract._id,
                method: selectedMethod?.name || "",
                methodType: "write",
                name: name,
                apiType: "native",
              });
            }}
          >
            Add
          </PrimaryButton>
        </div>

        <ExitActionBarButton />
      </div>
    </>
  );
};

export default AddCustomAPI;
