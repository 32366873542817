import Select, { Option } from "components/inputs/Select";
import TextInput from "components/inputs/TextInput";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { allowedCondtionalNetworks } from "utils/allSupportedNetwork";

type WhitelistNftType = {
  nftData: {
    id: string;
    address: string;
    networkId: string;
  };
  updateNetworkId: (id: string, networkId: string) => void;
  deleteHandeler: (removeId: string) => void;
  updateContractAddress: (id: string, address: string) => void;
};

const WhitelistNFT: React.FC<WhitelistNftType> = (props) => {
  const initialNetwork = allowedCondtionalNetworks.find(
    (element) => element.id === props.nftData.networkId
  );
  const [selectedNetwork, setSelectedNetwork] = React.useState<Option>(
    initialNetwork || allowedCondtionalNetworks[0]
  );

  return (
    <div className="flex justify-between items-center max-w-m">
      <div className="flex justify-between gap-2 items-center w-full">
        <TextInput
          className="w-1/2 mt-1 text-sm"
          placeholder="Nft Contract Address"
          value={props.nftData.address}
          onChange={(e: any) => {
            props.updateContractAddress(props.nftData.id, e.target.value);
          }}
        />
        <div className="w-1/2 text-sm">
          <Select
            options={allowedCondtionalNetworks}
            selected={selectedNetwork}
            onChange={(newSelectedNetwork) => {
              setSelectedNetwork(newSelectedNetwork);
              props.updateNetworkId(props.nftData.id, newSelectedNetwork.id);
            }}
          />
        </div>
      </div>
      <div className="flex justify-center w-1/6">
        <div className="py-4 mx-auto cursor-pointer">
          <AiOutlineDelete
            className="mx-auto"
            onClick={() => props.deleteHandeler(props.nftData.id)}
          />
        </div>
      </div>
    </div>
  );
};

export default WhitelistNFT;
