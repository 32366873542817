import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

// interface IData {}

export default function useGenerateTokenMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      const result = await api.post(`/user/generate-token`);
      return result.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
      },
    }
  );
}
