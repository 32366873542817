import { Dispatch, SetStateAction } from "react";
import Select from "react-select";

interface MultiSelectProp {
  category: {
    value: string;
    label: string;
  }[];
  setCategory: Dispatch<SetStateAction<{ value: string; label: string }[]>>;
}

export const businessCategoryTypes = [
  { value: "defi", label: "DeFi" },
  { value: "nft", label: "NFTs" },
  { value: "dex", label: "DEX" },
  { value: "metaverse", label: "Metaverse" },
  { value: "gaming", label: "Gaming" },
  { value: "other", label: "Other" },
];

const MultiSelect: React.FC<MultiSelectProp> = (
  { category, setCategory },
  ...props
) => {
  return (
    <Select
      closeMenuOnSelect={true}
      defaultValue={category}
      options={businessCategoryTypes}
      isMulti={true}
      value={category}
      onChange={(selected: any) => {
        setCategory(selected);
      }}
      {...props}
      // styles={colourStyles}
    />
  );
};

export default MultiSelect;
