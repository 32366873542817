import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Link, useMatch, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

interface IDappDetailsTabsProps {}

const DappDetailsTabs: React.FunctionComponent<IDappDetailsTabsProps> = (
  props
) => {
  const { dappId } = useParams();
  const tabs = [
    {
      title: "Overview",
      active: !!useMatch("/dapps/:dappId/overview"),
      linkTo: `/dapps/${dappId}/overview`,
    },
    {
      title: "Smart Contracts",
      active: !!useMatch("/dapps/:dappId/smart-contracts"),
      linkTo: `/dapps/${dappId}/smart-contracts`,
    },
    {
      title: "Meta Transaction Limits",
      active: !!useMatch("/dapps/:dappId/meta-transaction-limits"),
      linkTo: `/dapps/${dappId}/meta-transaction-limits`,
    },
    // {
    //   title: "API Whitelist Token",
    //   active: !!useMatch("/dapps/:dappId/api-whitelist-token"),
    //   linkTo: `/dapps/${dappId}/api-whitelist-token`,
    // },
    {
      title: "Dapp APIs",
      active: !!useMatch("/dapps/:dappId/dapp-apis"),
      linkTo: `/dapps/${dappId}/dapp-apis`,
    },
  ];

  return (
    <PrimaryTabsContainer>
      <PrimaryTabsChildren tabs={tabs} />
    </PrimaryTabsContainer>
  );
};

interface IPrimaryTabsContainer {}

const PrimaryTabsContainer: React.FC<IPrimaryTabsContainer> = ({
  children,
}) => {
  return (
    <Scrollbars
      className="overflow-x-auto overflow-y-hidden w-full mb-8"
      autoHeight
    >
      <div className="w-fit">
        <div className="flex gap-7 relative text-lg text-foreground-lightest">
          <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-light-border rounded-md"></div>
          {children}
        </div>
      </div>
    </Scrollbars>
  );
};

type Tab = {
  title: string;
  active: boolean;
  linkTo: string;
};

interface IPrimaryTabsChildren {
  tabs: Tab[];
}

const PrimaryTabsChildren: React.FC<IPrimaryTabsChildren> = ({ tabs }) => {
  return (
    <>
      {tabs.map((tab, index) => (
        <Link to={tab.linkTo} key={index}>
          <div className="flex-col gap-5 flex">
            <span
              className={twMerge(
                "whitespace-nowrap",
                tab.active && "text-biconomy-orange"
              )}
            >
              {tab.title}
            </span>
            <div
              className={twMerge(
                "h-[2px]",
                tab.active && "bg-biconomy-orange z-10"
              )}
            ></div>
          </div>
        </Link>
      ))}
    </>
  );
};

export default DappDetailsTabs;
