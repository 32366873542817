import PrimaryButton from "components/buttons/PrimaryButton";
import {
  DataCardBody,
  DataCardHeaderContainer,
  DataCardHeading,
} from "pages/home/components/DataCard";
import Table from "pages/home/layouts/dapps/details/components/Table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useMetaApiQuery, { MetaApi } from "hooks/useMetaApiQuery";
import { useActionBar } from "context/ActionBar";
import AddCustomAPI from "../components/AddCustomAPI";
import useDeleteCustomApiMutation from "hooks/mutations/deleteCustomApiMutation";

interface IDappAPIsProps {}

const DappAPIs: React.FunctionComponent<IDappAPIsProps> = () => {
  // fetch and update meta api list to state
  const { dappId } = useParams();
  const metaQuery = useMetaApiQuery(dappId!);
  const [metaApiList, setMetaApiList] = useState<MetaApi[]>();
  const navigate = useNavigate();

  useEffect(() => {
    if (metaQuery.data) setMetaApiList(metaQuery.data);
  }, [metaQuery.data]);

  // add custom api sidebar logic
  const { setCurrentNode } = useActionBar();
  function toggleAddCustomAPI(dappId: string) {
    setCurrentNode(<AddCustomAPI dappId={dappId} />);
  }
  const deleteCustomApi = useDeleteCustomApiMutation();

  const deleteApi = useCallback(
    async (dappId: string, apiId: string) => {
      deleteCustomApi.mutate({ dappId, apiId });
    },
    [deleteCustomApi]
  );

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Method", accessor: "method" },
    {
      Header: "Condition",
      accessor: "conditional",
      className: "text-center",
      cellClassName: "text-center",
    },
    {
      Header: "API ID",
      accessor: "apiId",
      cellClassName: "whitespace-nowrap",
    },
    {
      Header: "Read/Write",
      accessor: "methodType",
      collapse: true,
      className: "text-center",
      cellClassName: "text-center",
    },
    {
      collapse: true,
      Header: "Action",
      cellClassName: "text-center",
      accessor: "action",
    },
  ];

  const customMetaApiList = useMemo(
    () =>
      metaApiList?.map((data, ind: number) => {
        return {
          id: ind,
          ...data,
          conditional: (
            <div
              className="flex justify-center items-center cursor-pointer rounded-md border-2 border-biconomy-orange w-max pl-2 pr-1 m-auto"
              onClick={() => {
                navigate(`/dapps/${dappId}/dapp-apis/${data.apiId}`);
              }}
            >
              {data.conditions && data.conditions.length > 0 ? "Edit" : "Add"}
              <MdOutlineModeEditOutline className="text-gray-600 ml-2" />
            </div>
          ),
          action: (
            <div className="flex justify-center">
              <AiOutlineDelete
                className="text-gray-600 cursor-pointer"
                onClick={() => deleteApi(data.dappId, data.apiId)}
              />
            </div>
          ),
        };
      }),
    [dappId, deleteApi, metaApiList, navigate]
  );

  return (
    <DataCardBody className="bg-black/5">
      <DataCardHeaderContainer sepratorClassName="bg-black/10">
        <DataCardHeading title="Dapp APIs" className=" text-black" />
        <div>
          <PrimaryButton
            className="bg-white px-4 py-2 text-black text-sm flex gap-2 font-medium"
            onClick={() => toggleAddCustomAPI(dappId!)}
          >
            <span>Add</span>
            <MdOutlineModeEditOutline className="h-4 w-auto" />
          </PrimaryButton>
        </div>
      </DataCardHeaderContainer>
      <Table columns={columns} data={customMetaApiList || []} />
    </DataCardBody>
  );
};

export default DappAPIs;
