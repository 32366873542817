import React from "react";
import Logo from "assets/branding/logo.svg";
import { HiHome } from "react-icons/hi";
// import { ImStatsDots } from "react-icons/im";
import { RiAccountCircleFill, RiLogoutCircleRLine } from "react-icons/ri";
import { Link, useMatch } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAuth } from "context/Auth";
import PrimaryButton from "components/buttons/PrimaryButton";
import useWeb3 from "hooks/useWeb3";

export interface ISideNavbarProps {}

const SideNavbar: React.FC = (props: ISideNavbarProps) => {
  const isDappsPageActive = useMatch("/dapps/*");
  const isAccountPageActive = useMatch("/account/*");
  // const isStatsPageActive = useMatch("/stats/*");
  const { logout } = useAuth();
  const [{ address, onboard, ens }, { disconnect }] = useWeb3();
  // const [buttonContent, setButtonContent] = useState("Connect Wallet");

  const buttonContent = address
    ? ens?.name
      ? ens.name
      : shorten(address)
    : "Connect Wallet";
  // useEffect(() => {
  //   if (ens && ens.name) {
  //     setButtonContent(ens.name);
  //   } else if (address) {
  //     setButtonContent(shorten(address));
  //   }
  // }, [address, ens]);

  const isConnected = !!address;

  return (
    <nav className="w-[300px] bg-background-shade sticky inset-y-0 left-0 px-12 flex flex-col">
      <div className="py-16">
        <img src={Logo} className="h-12 w-auto text-left" alt="" />
      </div>
      <ul className="list-none flex-col flex gap-6 text-lg font-medium flex-grow">
        <Link to="/dapps">
          <li
            className={twMerge(
              "text-foreground-lightest flex items-center gap-4",
              isDappsPageActive && "text-foreground"
            )}
          >
            <HiHome className="w-5 h-auto" />
            <span>Home</span>
          </li>
        </Link>
        <Link to="/account">
          <li
            className={twMerge(
              "text-foreground-lightest flex items-center gap-4",
              isAccountPageActive && "text-foreground"
            )}
          >
            <RiAccountCircleFill className="w-5 h-auto" />
            <span>Account</span>
          </li>
        </Link>
        {/* <Link to="/statistics">
          <li
            className={twMerge(
              "text-foreground-lightest flex items-center gap-4",
              isStatsPageActive && "text-foreground"
            )}
          >
            <ImStatsDots className="w-4 h-auto mx-0.5" />
            <span>Statistics</span>
          </li>
        </Link> */}
        <li
          className="text-foreground-lightest flex items-center gap-4 mt-auto cursor-pointer"
          onClick={() => logout()}
        >
          <RiLogoutCircleRLine className="w-5 h-auto" />
          <span>Logout</span>
        </li>
      </ul>
      <div className="h-0 border-light-border border my-12"></div>
      <div className="flex mb-2 items-center justify-between">
        <PrimaryButton
          className="w-full flex justify-center py-3.5 rounded-full"
          onClick={async () => {
            if (await onboard?.walletSelect()) await onboard?.walletCheck();
          }}
          type="submit"
        >
          {buttonContent}
        </PrimaryButton>
      </div>

      <div className="flex mb-12 items-center justify-between">
        {isConnected && (
          <PrimaryButton
            key="disconnect"
            className="w-full flex justify-center py-2 rounded-full"
            onClick={async () => {
              disconnect();
            }}
          >
            Disconnect
          </PrimaryButton>
        )}
      </div>
    </nav>
  );
};

function shorten(address: string | null | undefined) {
  if (!address) return "";
  return address.slice(0, 5) + "..." + address.slice(-4);
}

export default SideNavbar;
