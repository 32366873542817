import { useQuery } from "react-query";
import api from "api/axios";

export type MetaApi = {
  name: string;
  method: string;
  apiId: string;
  dappId: string;
  methodType: string;
  contractId: string;
  conditions: any;
};

export default function useMetaApiQuery<TData = MetaApi[]>(
  dappId: string,
  // options?: UseQueryOptions<MetaApi[], Error, TData>
) {
  return useQuery<MetaApi[], Error, TData>(
    ["metaApiByDappId"],
    async () => {
      try {
        const metaApisResponse = await api.get(`/dapp/${dappId}/meta-api`);
        if (!metaApisResponse.data?.data?.apis) return [];

        const metaApisList: MetaApi[] = metaApisResponse.data?.data?.apis;
        return metaApisList;
      } catch (error: any) {
        if (error?.response?.status === 404) {
          return [];
        } else {
          throw error;
        }
      }
    },
  );
}
