import React from "react";
import { twMerge } from "tailwind-merge";
import PrimaryButton, { PrimaryButtonProps } from "./PrimaryButton";

export const PrimaryOutlineButton: React.FC<PrimaryButtonProps> = ({
  className,
  ...props
}) => {
  return (
    <PrimaryButton
      {...props}
      className={twMerge("bg-white text-biconomy-orange", className)}
    >
      {props.children}
    </PrimaryButton>
  );
};

export default PrimaryOutlineButton;
