import React from "react";
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom";
import Overview from "./layouts/Overview";
import SmartContracts from "./layouts/SmartContracts";
import MetaTransactionLimits from "./layouts/MetaTransactionLimits";
import PrimaryButton from "components/buttons/PrimaryButton";
import DappDetailsTabs from "./components/DappDetailsTabs";
import Breadcrumbs from "pages/home/components/Breadcrumbs";
import DappAPIs from "./layouts/DappAPIs";
import Logo from "assets/branding/logo.svg";
import PrimaryOutlineButton from "components/buttons/PrimaryOutlineButton";
import DappStats from "./layouts/DappStats";
import useDappQuery from "hooks/useDappQuery";
import { useActionBar } from "context/ActionBar";
import FillGas from "./components/FillGas";
import DappConfigure from "./layouts/DappConfigure";
import DappAPIEdit from "./layouts/DappAPIEdit";

const DappDetails: React.FC = (props) => {
  return (
    <Routes>
      <Route path="/stats" element={<DappStats />} />
      <Route path="/configure" element={<DappConfigure />} />
      <Route path="*" element={<DappConfigTabs />} />
    </Routes>
  );
};

const DappConfigTabs: React.FC = (props) => {
  const { dappId } = useParams();
  const dappQuery = useDappQuery(dappId!);

  const { setCurrentNode } = useActionBar();

  function toggleFillGas() {
    setCurrentNode(
      <FillGas
        dappData={dappQuery.data!}
        networkId={dappQuery.data?.networkId!}
      />
    );
  }

  if (!dappQuery.isFetchedAfterMount) {
    return (
      <>
        <div className="flex items-center justify-between">
          <h1 className="text-3xl tracking-tight my-2">
            {dappQuery.data?.dappName}
          </h1>
        </div>
        <Breadcrumbs
          crumbs={[
            { title: "Your dapps", linkTo: "/dapps" },
            {
              title: dappQuery.data?.dappName || "Your Dapp",
              linkTo: `/dapps/${dappId}`,
            },
          ]}
        />
        <div className="flex flex-col w-full mt-20 justify-center items-center">
          <img
            src={Logo}
            className="animate-bounce h-12 w-auto text-left"
            alt=""
          />
          <span className="m-5">Fetching data...</span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-3xl truncate tracking-tight max-w-[300px] my-2">
          {dappQuery.data?.dappName}
        </h1>
        <div className="flex gap-3">
          <Link to={`/dapps/${dappId}/stats`}>
            <PrimaryOutlineButton className="px-7 py-3">
              View Stats
            </PrimaryOutlineButton>
          </Link>
          <Link to={`/dapps/${dappId}/configure`}>
            <PrimaryOutlineButton className="px-7 py-3">
              Configure
            </PrimaryOutlineButton>
          </Link>
          <PrimaryButton className="px-7 py-3" onClick={toggleFillGas}>
            Fill Gas
          </PrimaryButton>
        </div>
      </div>
      <Routes>
        <Route
          path="/dapp-apis/:apiId"
          element={
            <Breadcrumbs
              crumbs={[
                { title: "Your dapps", linkTo: "/dapps" },
                {
                  title: dappQuery.data?.dappName || "Your Dapp",
                  linkTo: `/dapps/${dappId}`,
                },
                {
                  title: "Edit Dapp Details",
                  linkTo: "#",
                },
              ]}
            />
          }
        />
        <Route
          path="/*"
          element={
            <Breadcrumbs
              crumbs={[
                { title: "Your dapps", linkTo: "/dapps" },
                {
                  title: dappQuery.data?.dappName || "Your Dapp",
                  linkTo: `/dapps/${dappId}`,
                },
              ]}
            />
          }
        />
      </Routes>

      <div className="w-full flex flex-col gap-2 mt-4">
        <DappDetailsTabs />
        <Routes>
          <Route
            path="/overview"
            element={<Overview dappData={dappQuery.data!} />}
          />
          <Route
            path="/smart-contracts"
            element={<SmartContracts networkId={dappQuery.data?.networkId!} />}
          />
          <Route
            path="/meta-transaction-limits"
            element={<MetaTransactionLimits />}
          />
          <Route path="/dapp-apis" element={<DappAPIs />} />
          <Route path="/dapp-apis/:apiId" element={<DappAPIEdit />} />
          {/* <Route path="/api-whitelist-token" element={<APIWhitelistToken />} /> */}
          <Route
            path="/*"
            element={<Navigate to={`/dapps/${dappId}/overview`} replace />}
          />
        </Routes>
      </div>
    </>
  );
};

export default DappDetails;
