import { useActionBar } from "context/ActionBar";
import { HiArrowSmLeft } from "react-icons/hi";

function ExitActionBarButton() {
  const { setCurrentNode } = useActionBar();

  return (
    <button
      onClick={() => setCurrentNode(undefined)}
      className="text-biconomy-orange font-semibold text-xs flex items-center mt-10"
    >
      <HiArrowSmLeft className="mr-1 h-4 w-auto" />
      Exit
    </button>
  );
}

export default ExitActionBarButton;
