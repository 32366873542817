import React from "react";
import { twMerge } from "tailwind-merge";

export interface IStepperProps {
  step: number;
}

export default function Stepper({ step }: IStepperProps) {
  const totalSteps = 4;
  const arrOfSteps = Array.from({ length: totalSteps }, (_, i) => i + 1);

  return (
    <div className="flex items-center my-10">
      {arrOfSteps.map((stepNumber) => {
        const isStarted = stepNumber === 1;
        return (
          <>
            {!isStarted && (
              <div
                className={twMerge(
                  "flex-grow border border-light-border h-0",
                  step >= stepNumber && "border-biconomy-orange"
                )}
              ></div>
            )}
            <div
              className={twMerge(
                "w-12 h-12 rounded-full border-light-border border-2 text-foreground-light flex items-center justify-center text-lg font-medium",
                step >= stepNumber &&
                  "border-none text-white bg-biconomy-orange"
              )}
            >
              {stepNumber}
            </div>
          </>
        );
      })}
    </div>
  );
}
