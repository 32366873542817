const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const formatDateXAxis = (tickItem: string) => {
    if(tickItem && tickItem !== "") {
        const dateParts = tickItem.split("-");  
        // month is 0-based, that's why we need dataParts[1] - 1
        const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
        return `${date.getDate()} ${months[date.getMonth()]}`;
    } else {
        return "NA";
    }
}

const formatTooltipDate = (tickItem: string) => {
    if(tickItem && tickItem !== "") {
        const dateParts = tickItem.split("-");  
        // month is 0-based, that's why we need dataParts[1] - 1
        const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
        const year = date.getFullYear().toString().substr(-2);
        return `${date.getDate()} ${months[date.getMonth()]} ${year}`;
    } else {
        return "NA";
    }
}


export { formatDateXAxis, formatTooltipDate }