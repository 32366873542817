import * as React from "react";
import { twMerge } from "tailwind-merge";

interface IDataCardHeaderContainerProps {
  sepratorClassName?: string;
  className?: string;
  noSeparator?: boolean;
}

const DataCardHeaderContainer: React.FunctionComponent<
  IDataCardHeaderContainerProps
> = ({ children, className, sepratorClassName, noSeparator }) => {
  return (
    <div className={twMerge("flex flex-col", className)}>
      <div className="flex justify-between pb-3 items-center">{children}</div>
      {!noSeparator && (
        <div
          className={twMerge(
            "h-[2px] bg-biconomy-orange/10",
            sepratorClassName
          )}
        ></div>
      )}
    </div>
  );
};

export default DataCardHeaderContainer;
