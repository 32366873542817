import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface IEditUserMutation {
  adminFullName?: string;
  adminUserName?: string;
  websiteUrl?: string;
  businessCategory?: Array<string>;
  description?: string;
  alternateEmail?: string;
  telegramId?: string;
  twitterId?: string;
  discordId?: string;
}

export default function useEditUserMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async (user: IEditUserMutation) => {
      const result = await api.post("/user/account", {
        adminFullName: user.adminFullName,
        adminUserName: user.adminUserName,
        websiteUrl: user.websiteUrl,
        businessCategory: user.businessCategory,
        description: user.description,
        adminEmail: user.alternateEmail,
        telegramId: user.telegramId,
        twitterId: user.twitterId,
        discordId: user.discordId,
        isOnboarded: true,
      });
      return result.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
      },
    }
  );
}
