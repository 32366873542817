import React from "react";
import { twMerge } from "tailwind-merge";

interface ILabelledDataFieldProps {
  label: React.ReactNode;
  data: React.ReactNode;
  isRightAligned?: boolean;
  labelClassName?: string;
  dataClassName?: string;
}

const LabelledDataField: React.FunctionComponent<ILabelledDataFieldProps> = ({
  label,
  data,
  isRightAligned,
  labelClassName,
  dataClassName,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <span
        className={twMerge(
          "uppercase text-foreground text-xs font-semibold",
          isRightAligned && "text-right",
          labelClassName
        )}
      >
        {label}
      </span>
      <span
        className={twMerge(
          "text-3xl font-extralight tracking-tight",
          isRightAligned && "text-right",
          dataClassName
        )}
      >
        {data}
      </span>
    </div>
  );
};

export default LabelledDataField;
