import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "pages/home/components/Breadcrumbs";
import {
  DataCardBody,
  DataCardHeaderContainer,
  DataCardHeading,
} from "pages/home/components/DataCard";
import useDappQuery from "hooks/useDappQuery";
import PrimaryButton from "components/buttons/PrimaryButton";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Table from "../components/Table";
import { AiOutlineDelete } from "react-icons/ai";
import useEditDappConfigMutation from "hooks/mutations/editDappConfig";
import useDappDappConfigsQuery from "hooks/useDappConfigQuery";
import { useActionBar } from "context/ActionBar";
import AddDappConfig from "../components/AddDappConfig";

interface IDappConfigureProps {}

const DappConfigure: React.FunctionComponent<IDappConfigureProps> = (props) => {
  const { dappId } = useParams();
  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);
  const [toggleConfig, setToggleConfig] = useState(false);
  // fetch all whitlist domains of dappid
  const dappQuery = useDappQuery(dappId!);

  const dappConfigQuery = useDappDappConfigsQuery(dappId!);
  useEffect(() => {
    if (
      dappConfigQuery.data?.allowedDomains &&
      dappConfigQuery.data?.allowedDomains.length
    )
      setAllowedDomains(dappConfigQuery.data.allowedDomains);
  }, [dappConfigQuery.data?.allowedDomains]);

  // create dapp config
  const { setCurrentNode } = useActionBar();

  useEffect(() => {
    if (toggleConfig) {
      setCurrentNode(
        <AddDappConfig dappId={dappId!} allowedDomains={allowedDomains} />
      );
    }
  }, [allowedDomains, dappId, setCurrentNode, toggleConfig]);

  // delete dapp config action based on domain
  const editDappConfig = useEditDappConfigMutation();
  const deleteConfig = useCallback(
    async (url: string) => {
      const newList = allowedDomains.filter((el) => el !== url);
      await editDappConfig.mutateAsync({
        dappId: dappId!,
        allowedDomains: newList,
      });
    },
    [allowedDomains, dappId, editDappConfig]
  );
  // edit allowed domains array row to render delete action
  const customConfig = useMemo(() => {
    if (
      dappConfigQuery.data &&
      dappConfigQuery.data.allowedDomains &&
      dappConfigQuery.data.allowedDomains.length
    )
      return dappConfigQuery.data.allowedDomains.map((config) => {
        return {
          allowedDomains: config,
          action: (
            <div className="flex justify-center">
              <AiOutlineDelete
                className="text-gray-600 cursor-pointer"
                onClick={() => deleteConfig(config)}
              />
            </div>
          ),
        };
      });
  }, [dappConfigQuery.data, deleteConfig]);

  const columns = [
    { Header: "Website URl", accessor: "allowedDomains" },
    {
      collapse: true,
      Header: "Action",
      cellClassName: "text-center",
      accessor: "action",
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-4xl tracking-tight my-2">
          {dappQuery.data?.dappName}
        </h1>
      </div>
      <Breadcrumbs
        crumbs={[
          { title: "Your dapps", linkTo: "/dapps" },
          {
            title: dappQuery.data?.dappName || "Your Dapp",
            linkTo: `/dapps/${dappId}`,
          },
          { title: "Configure", linkTo: `/dapps/${dappId}/configure` },
        ]}
      />

      <hr className="bg-midnight" />
      <hr className="bg-midnight" />

      <DataCardBody className="bg-black/5 mt-5">
        <DataCardHeaderContainer sepratorClassName="bg-black/10">
          <DataCardHeading title="Allowed Domains" className=" text-black" />
          <div>
            <PrimaryButton
              onClick={() => setToggleConfig(true)}
              className="bg-white px-4 py-2 text-black text-sm flex gap-2 font-medium"
            >
              <span>Add</span>
              <MdOutlineModeEditOutline className="h-4 w-auto" />
            </PrimaryButton>
          </div>
        </DataCardHeaderContainer>
        <Table columns={columns} data={customConfig || []} />
      </DataCardBody>
    </>
  );
};

export default DappConfigure;
