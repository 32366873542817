import React from "react";
// import PrimaryButton from "components/buttons/PrimaryButton";
// import { BsPlusLg } from "react-icons/bs";

interface ICreateNewDappProps {}

const CreateNewDapp: React.FC<ICreateNewDappProps> = (props) => {
  return (
    <>
      <div className="mt-10 w-full flex flex-col p-8 gap-8">
        {/* <div className="flex">
          <div className="rounded-full bg-biconomy-orange p-6">
            <BsPlusLg className="text-white h-10 w-auto" />
          </div>
        </div> */}
        <div className="text-foreground">
          <p>Hey! 👋</p>
          <p>
            Looks like you have no registered dApps. Let's register your first
            dApp now!
          </p>
        </div>
        {/* <PrimaryButton className="py-3">Register now! &#8594;</PrimaryButton> */}
      </div>
    </>
  );
};

export default CreateNewDapp;
