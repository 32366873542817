import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface INewSCWSmartContractData {
  dappId: string;
  secwallet: string;
}

export default function useAddSCWSmartContractMutation() {
  const queryClient = useQueryClient();
  // options?: UseQueryOptions<Dapp[], Error, TData>
  return useMutation(
    async (sc: INewSCWSmartContractData) => {
      let postApi =
        sc.secwallet === "biconomy"
          ? `/dapp/${sc.dappId}/register-biconomy-wallet`
          : `/dapp/${sc.dappId}/register-gnosis-wallet`;
      const { data } = await api.post(postApi);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["smartContractsByDappId"]);
      },
    }
  );
}
