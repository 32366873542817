import PrimaryButton from "components/buttons/PrimaryButton";
import { useActionBar } from "context/ActionBar";
import useUserQuery from "hooks/useUserQuery";
import {
  DataCardBody,
  DataCardHeaderContainer,
  DataCardHeading,
  LabelledDataField,
  LabelledDataFieldContainer,
} from "pages/home/components/DataCard/index";
import { useEffect } from "react";
import { BiCog } from "react-icons/bi";
// import { BsWrench } from "react-icons/bs";
import { Link } from "react-router-dom";
// import ChangePlan from "./components/ChangePlan";

export interface IAccountOverviewProps {}

export function AccountOverview(props: IAccountOverviewProps) {
  const userQuery = useUserQuery();
  const { setCurrentNode } = useActionBar();

  useEffect(() => {
    return () => {
      setCurrentNode(undefined);
    };
  }, [setCurrentNode]);

  // function toggleChangePlan() {
  //   setCurrentNode(<ChangePlan />);
  // }

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-4xl tracking-tight my-2">
          Hey, {userQuery.data?.email.split("@")[0]}!
        </h1>
        {/* <PrimaryButton className="py-3.5 px-7 gap-3" onClick={toggleChangePlan}>
          <BsWrench className="h-3.5 w-auto" />
          <span>Change Plan</span>
        </PrimaryButton> */}
      </div>

      {/* <DataCardBody className="bg-black text-white mt-12">
        <DataCardHeaderContainer sepratorClassName="bg-white/25">
          <DataCardHeading title="Overview" className="text-2xl text-white" />
          <span>Auth Token: 5b0ef8e9-024f-47d3-91db-867a1a9b3037</span>
        </DataCardHeaderContainer>
        <LabelledDataFieldContainer>
          <LabelledDataField
            label="Current Plan"
            data="Developer"
            labelClassName="text-white"
            dataClassName="text-3xl"
          />
          <LabelledDataField
            label="Dapp Limit"
            data="N/A"
            labelClassName="text-white"
            dataClassName="text-3xl"
            isRightAligned
          />
        </LabelledDataFieldContainer>
        <LabelledDataFieldContainer>
          <LabelledDataField
            label="Expiration"
            dataClassName="text-3xl"
            data="131 days"
            labelClassName="text-white"
          />
        </LabelledDataFieldContainer>
        <LabelledDataFieldContainer>
          <LabelledDataField
            label="Total Transactions"
            dataClassName="text-3xl"
            data="23123"
            labelClassName="text-white"
          />
        </LabelledDataFieldContainer>
      </DataCardBody> */}

      <DataCardBody className="bg-black/5 mt-10">
        <DataCardHeaderContainer sepratorClassName="bg-black/10">
          <DataCardHeading
            title="Account Details"
            className="text-xl text-black"
          />
          <div>
            <Link to="/account/edit">
              <PrimaryButton className="bg-white px-4 py-2 text-black text-sm flex gap-2 font-medium">
                <span>Edit</span>
                <BiCog className="h-4 w-auto" />
              </PrimaryButton>
            </Link>
          </div>
        </DataCardHeaderContainer>
        <LabelledDataFieldContainer>
          <LabelledDataField
            label="Admin Username"
            data={userQuery.data?.adminUserName}
            dataClassName="text-lg"
          />
          <LabelledDataField
            label="Email address"
            data={userQuery.data?.email}
            dataClassName="text-lg"
            isRightAligned
          />
        </LabelledDataFieldContainer>

        <LabelledDataFieldContainer>
          <LabelledDataField
            label="Auth Token"
            data={userQuery.data?.authToken}
            dataClassName="text-lg"
          />
        </LabelledDataFieldContainer>
      </DataCardBody>
    </>
  );
}
