import React, { useEffect, useState } from "react";
// import Select from "components/inputs/Select";
import { useActionBar } from "context/ActionBar";
import useDappsListQuery from "hooks/useDappsListQuery";
import Logo from "assets/branding/logo.svg";
import CreateNewDapp from "./components/CreateNewDapp";
import DappSummary from "./components/DappSummary";
import NewDappForm from "./components/NewDappForm";
import DeleteDapp from "./components/DeleteDapp";

const DappList: React.FC = () => {
  const { setCurrentNode } = useActionBar();
  const dappsListQuery = useDappsListQuery();

  // for delete dapp
  const [modalState, setModalState] = useState(false);
  const [deleteDappId, setDeleteDappId] = useState("");

  useEffect(() => {
    setCurrentNode(<NewDappForm />);
    return () => {
      setCurrentNode(undefined);
    };
  }, [setCurrentNode]);

  if (!dappsListQuery.isFetchedAfterMount) {
    return (
      <>
        <h1 className="text-4xl tracking-tight my-2">Your dapps</h1>
        <div className="flex flex-col w-full mt-20 justify-center items-center">
          <img
            src={Logo}
            className="animate-bounce h-12 w-auto text-left"
            alt=""
          />
          <span className="m-5">Fetching data...</span>
        </div>
      </>
    );
  }

  return (
    <>
      <DeleteDapp
        modalState={modalState}
        setModalState={setModalState}
        deleteDappId={deleteDappId}
      />
      <div className="flex items-center justify-between">
        <h1 className="text-4xl tracking-tight my-2">Your dapps</h1>
        {/* <div className="flex gap-2">
          <div className="max-w-[200px]">
            <Select
              disabled={true}
              options={[{ title: "All Networks" }]}
              selected={{ title: "All Networks" }}
              onChange={() => {}}
            />
          </div>
          <div className="max-w-[200px]">
            <Select
              disabled={true}
              options={[{ title: "Most Recent" }]}
              selected={{ title: "Most Recent" }}
              onChange={() => {}}
            />
          </div>
        </div> */}
      </div>
      {dappsListQuery.data && dappsListQuery.data.length > 0 ? (
        <div className="grid grid-cols-2 place-items-center gap-12 mt-12">
          {dappsListQuery.data.map((dapp) => (
            <DappSummary
              key={dapp.dappId}
              dappId={dapp.dappId}
              dappName={dapp.dappName}
              networkId={dapp.networkId}
              transactionCountInTotal={dapp.transactionCountInTotal}
              transactionCountThisMonth={dapp.transactionCountThisMonth}
              gasTankBalance={dapp.gasTankBalance}
              gasTankDepletionRate={dapp.gasTankDepletionRate}
              oustandingBalance={dapp.oustandingBalance}
              setDeleteDappId={setDeleteDappId}
              setModalState={setModalState}
            />
          ))}
        </div>
      ) : (
        <CreateNewDapp />
      )}
    </>
  );
};

export default DappList;
