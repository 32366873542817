import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface MetaTxLimitData {
  userId: string;
  dappId: string;
  entityType: string;
  durationValue: number;
  durationUnit: string;
  value: number;
  limitDurationInMs: number;
  limitStartTime: number;
  type: number;
  limitStatus: boolean;
  toggle: boolean;
}

export default function useMetaTxLimitMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: MetaTxLimitData) => {
      try {
        await api.patch(`/dapp/${data.dappId}/limit `, {
          userId: data.userId,
          dappId: data.dappId,
          entityType: data.entityType,
          durationValue: data.durationValue,
          durationUnit: data.durationUnit,
          value: data.value,
          limitDurationInMs: data.limitDurationInMs,
          limitStartTime: data.limitStartTime,
          type: data.type,
          limitStatus: data.limitStatus,
          toggle: data.toggle,
        });
      } catch (err: any) {
        throw new Error(err.response.data.error || err.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dapps"]);
      },
    }
  );
}
