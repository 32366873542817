import React, { useState } from "react";
import PrimaryButton from "components/buttons/PrimaryButton";
import Alert from "components/Alert";
import useGenerateTokenMutation from "hooks/mutations/generateTokenMutation";

const ReGenerateToken: React.FC = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const useGenerate = useGenerateTokenMutation();

  return (
    <>
      <div className="flex flex-col gap-6 text-foreground">
        <h1 className="text-xm font-bold">Generate Token</h1>
        <span className="text-xs text-foreground mt-2">
          You can use the Auth token in API to add/delete smart contract & methods.
        </span>
        <PrimaryButton
          className="py-3"
          onClick={async () => {
            setSuccess("");
            setError("");
            try {
              await useGenerate.mutateAsync();
              setSuccess("Updated Successfully");
            } catch (err: any) {
              console.error(err);
              setError(err.response.data.error || err.message);
            }
          }}
        >
          Regenerate Token
        </PrimaryButton>
        {/* TODO: this should be shown once the above mutation succeeds. */}
        {error && <Alert color="error" message={error} />}
        {success && <Alert color="success" message={success} />}
      </div>
    </>
  );
};

export default ReGenerateToken;
