import React, { useCallback, useEffect, useState } from "react";
import Decimal from "decimal.js";
import ExitActionBarButton from "components/buttons/ExitActionBarButton";
import PrimaryButton from "components/buttons/PrimaryButton";
import TextInput from "components/inputs/TextInput";
import { getNetworkDetail } from "utils/allSupportedNetwork";
import Alert from "components/Alert";
import useWeb3 from "hooks/useWeb3";
import { ethers } from "ethers";
import config from "utils/config";
import { Dapp } from "hooks/useDappsListQuery";
import { useQueryClient } from "react-query";
import { AlertIcon } from "assets/icons/Icons";

interface IFillGasProps {
  dappData: Dapp;
  networkId: string;
}

const FillGas: React.FC<IFillGasProps> = ({ dappData, networkId }) => {
  const queryClient = useQueryClient();
  const [finalAmout, setFinalAmout] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [depositAmountReadable, setDepositAmountReadable] = useState("0");
  const [contractMinDepositWei, setContractMinDepositWei] = useState(
    new Decimal(0)
  );
  const [transactionResponse, setTransactionResponse] = useState("");
  // const [balanceUpdated, setBalanceUpdated] = useState(false);

  const [{ address, wallet, network }] = useWeb3();
  const currency = getNetworkDetail(networkId, "currency");

  // initialize gas tank contract instance
  const getGasTankContract = useCallback(async () => {
    if (!wallet?.provider || !ethers.utils.isAddress(address!)) {
      setError("Connect wallet to fill gas tank ⛽️");
      return;
    }
    if (network && parseInt(networkId) !== network) {
      setError(`Connect to ${getNetworkDetail(networkId, "title")} network`);
      return;
    }
    setError("");

    const provider = new ethers.providers.Web3Provider(wallet.provider);
    const signer = provider.getSigner(address);
    const gasTankAddresses =
      config.network[networkId.toString()].gasTankAddresses;

    const abi = config.abi.dappGasTank;
    const contract = new ethers.Contract(gasTankAddresses, abi, signer);

    return contract;
  }, [address, network, networkId, wallet]);

  useEffect(() => {
    (async () => {
      try {
        const contract = await getGasTankContract();
        if (!contract) return;
        const minDeposit = await contract.minDeposit();
        setContractMinDepositWei(new Decimal(minDeposit.toString()));
      } catch (e) {
        console.error("Failed to fetch min deposit value from contract", e);
      }
    })();
  }, [address, getGasTankContract, networkId]);

  const getMinimumValidDepositAmount = () => {
    try {
      const contractMinDeposit = contractMinDepositWei
        .div(Math.pow(10, 18))
        .toNumber();
      let minDepositAmount = contractMinDeposit;

      if (dappData.oustandingBalance > 0) {
        const effectiveBalance = new Decimal(dappData.effectiveBalance)
          .div(Math.pow(10, 18))
          .toNumber();
        minDepositAmount = Math.abs(effectiveBalance) + contractMinDeposit;
      }

      return minDepositAmount;
    } catch (e) {
      console.error("Unable to getMinimumDepositAmount");
      return new Decimal(0);
    }
  };

  const getMaximumValidDepositAmount = () => {
    if (dappData && dappData.gasThreshold) {
      try {
        let maxDepositAmount;
        const gasThreshold = new Decimal(dappData.gasThreshold)
          .div(Math.pow(10, 18))
          .toNumber();
        const effectiveBalance = new Decimal(dappData.effectiveBalance)
          .div(Math.pow(10, 18))
          .toNumber();
        const contractMinDeposit = contractMinDepositWei
          .div(Math.pow(10, 18))
          .toNumber();
        const lastOutstandingBalance = new Decimal(
          dappData.lastOutstandingBalance
        )
          .div(Math.pow(10, 18))
          .toNumber();
        if (Math.round(lastOutstandingBalance) > 0) {
          const max = Math.max(contractMinDeposit, gasThreshold);
          const abs = Math.abs(effectiveBalance);
          maxDepositAmount = max + abs;
        } else {
          maxDepositAmount = Math.max(contractMinDeposit, gasThreshold);
        }
        return maxDepositAmount;
      } catch (e) {
        console.error(e);
        console.error("Unable to getMaxmimumDepositAmount");
        return 0;
      }
    } else return 0;
  };

  const getMetamaskErrorReadableMessage = (messageException: any) => {
    try {
      if (messageException.code) {
        const { message } = config.metamaskErrorValues[messageException.code];
        if (message && message.length !== 0) {
          return `Error: ${message}`;
        }
      }
    } catch (e) {
      console.error("Error while determining metamask error");
    }
    return messageException.message.split(0, 10);
  };

  const handleFillTank = async (e: any) => {
    e.preventDefault();
    setError("");
    const oldBalance = new Decimal(dappData.effectiveBalance)
      .div(Math.pow(10, 18))
      .toNumber();
    if (!depositAmountReadable || depositAmountReadable === "0") {
      setError("Enter correct deposit amount");
      setLoading(false);
      return;
    }
    console.log("Filling ⛽", dappData);
    try {
      setLoading(true);
      if (!dappData.fundingKey || dappData.oustandingBalance === undefined) {
        setError("Invalid dapp data");
        setLoading(false);
        return;
      }

      const valueWei = ethers.BigNumber.from(
        new Decimal(depositAmountReadable)
          .mul(new Decimal(10).pow(18))
          .toFixed()
      );
      // if (contractMinDepositWei.toString() > valueWei.toString()) {
      // }

      const contract = await getGasTankContract();
      if (!contract) {
        setLoading(false);
        return;
      }

      const tx = await contract.depositFor(dappData.fundingKey, {
        value: valueWei,
      });
      console.log(tx);
      setTransactionResponse(tx.hash as string);
      queryClient.invalidateQueries(["dapps"]);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError(getMetamaskErrorReadableMessage(err));
      console.error(err);
    }

    try {
      // Keep pinging API until balance is updated.
      let _balanceUpdated = false;
      while (!_balanceUpdated) {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            queryClient
              .invalidateQueries(["dapps"])
              .then(resolve)
              .catch((e) => reject(e));
          }, 4000);
        });
        // console.log(dappData.gasTankBalance, oldBalance);
        if (dappData.gasTankBalance > oldBalance) {
          _balanceUpdated = true;
        }
      }
    } catch (e) {
      console.error("Error processing after process callback", e);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-6">
          <h1 className="text-xm font-bold">Fill Gas</h1>
          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Deposit Amount</label>
            <TextInput
              placeholder={`0.01 ${currency}`}
              type="number"
              value={depositAmountReadable}
              onKeyDown={(e: any) => {
                if (e.target.valueAsNumber < 0) {
                  setDepositAmountReadable("0");
                  setFinalAmout("0");
                }
              }}
              onBlur={(e: any) => {
                if (e.target.valueAsNumber < 0) {
                  setDepositAmountReadable("0");
                  setFinalAmout("0");
                }
              }}
              onChange={(e: any) => {
                setDepositAmountReadable(e.target.valueAsNumber);
                const finalBal =
                  Number(e.target.valueAsNumber) +
                  dappData.gasTankBalance -
                  dappData.oustandingBalance;
                setFinalAmout(
                  isNaN(finalBal) ? "0" : finalBal.toString().substring(0, 7)
                );
              }}
            />
            <span className="flex flex-col">
              <span className="text-[0.725rem] text-grey-400">
                Minimum Deposit Amount:{" "}
                {getMinimumValidDepositAmount().toFixed(5)}
              </span>
              {/* <span className="text-[0.725rem] text-red-400">
                Recommended Deposit Amount based on Transaction Activity:{" "}
                {getMaximumValidDepositAmount().toFixed(5)}
              </span> */}
            </span>
          </div>

          <span className="flex flex-col">
            <span className="text-[0.625rem] text-black uppercase font-bold">
              Gas Balance
            </span>
            <span className="text-2xl text-black font-light">
              {dappData.gasTankBalance.toFixed(5)} {currency}
            </span>
          </span>

          <span className="flex flex-col">
            <span className="text-[0.625rem] text-black uppercase font-bold">
              Outstanding
            </span>
            <span className="text-2xl text-black font-light">
              {dappData.oustandingBalance} {currency}
            </span>
          </span>

          <span className="flex flex-col">
            <span className="text-[0.625rem] text-black uppercase font-bold">
              Final Balance
            </span>
            <span className="text-2xl text-black font-light">
              {finalAmout} {currency}
            </span>
          </span>
          {error && <Alert color="error" message={error} />}
          <PrimaryButton
            className="py-3"
            disabled={loading}
            onClick={handleFillTank}
          >
            Fill Me Up
          </PrimaryButton>
        </div>

        {transactionResponse && (
          <div
            className="p-3 items-center leading-none lg:rounded-xl flex lg:inline-flex mt-4"
            style={{ backgroundColor: "#3FBF621A" }}
            role="alert"
          >
            <AlertIcon color={"3FBF62"} />
            <div className="flex flex-col gap-2 pl-2">
              <span className="font-thin text-sm mr-2 flex-auto">
                Transaction status
              </span>
              <a
                className="text-biconomy-orange"
                href={getNetworkDetail(networkId!, "url") + transactionResponse}
                target="_blank"
                rel="noopener noreferrer"
              >
                {transactionResponse.slice(0, 5) +
                  "..." +
                  transactionResponse.slice(-4)}
              </a>
            </div>
          </div>
        )}

        <ExitActionBarButton />
      </div>
    </>
  );
};

export default FillGas;
