import React, { createContext, useContext, useState } from "react";

interface IActionBarContext {
  setCurrentNode: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  currentNode: React.ReactNode;
}

const ActionBarContext = createContext<IActionBarContext | null>(null);

const ActionBarProvider: React.FC = (props) => {
  const [currentNode, setCurrentNode] = useState<React.ReactNode>(undefined);

  return (
    <ActionBarContext.Provider
      value={{ currentNode, setCurrentNode }}
      {...props}
    />
  );
};

const useActionBar = () => {
  const actionBarContext = useContext(ActionBarContext);
  if (!actionBarContext) {
    throw new Error("Action Bar Context Context used without provider");
  }
  return actionBarContext;
};
export { ActionBarProvider, useActionBar };
