import React, { useEffect, useContext, useState } from "react";
import { ethers, providers } from "ethers";
import { API, Wallet, Ens } from "bnc-onboard/dist/src/interfaces";

import { initOnboard } from "../utils/initOnboard";

interface ContextData {
  address?: string;
  ens?: Ens;
  network?: number;
  balance?: string;
  wallet?: Wallet;
  onboard?: API;
}

interface ContextActions {
  ready: () => Promise<boolean>; // function as property declaration
  disconnect: () => void;
}

type Context = [ContextData, ContextActions];

let defaultProvider: providers.JsonRpcProvider = new providers.InfuraProvider(
  1
);
let provider: providers.JsonRpcProvider | undefined;
const Web3Context = React.createContext<Context>([
  {},
  {
    ready: async () => {
      return false;
    },
    disconnect: () => {},
  },
]);
export const Web3Provider: React.FC<{}> = ({ children }) => {
  const [address, setAddress] = useState<string>();
  const [ens, setEns] = useState<Ens>();
  const [network, setNetwork] = useState<number>();
  const [balance, setBalance] = useState<string>();
  const [wallet, setWallet] = useState<Wallet>();
  const [onboard, setOnboard] = useState<API>();

  useEffect(() => {
    const onboard = initOnboard({
      address: setAddress,
      ens: setEns,
      network: setNetwork,
      balance: setBalance,
      wallet: (wallet: Wallet) => {
        if (wallet.provider) {
          setWallet(wallet);

          provider = new ethers.providers.Web3Provider(wallet.provider);

          window.localStorage.setItem("selectedWallet", wallet.name!);
        } else {
          provider = undefined;
          setWallet(undefined);
        }
      },
    });
    setOnboard(onboard);
  }, []);

  useEffect(() => {
    const previouslySelectedWallet =
      window.localStorage.getItem("selectedWallet");
    if (previouslySelectedWallet && onboard) {
      (async () => {
        await onboard.walletSelect(previouslySelectedWallet);
        await onboard.walletCheck();
      })();
    }
  }, [onboard]);

  useEffect(() => {
    (async () => {
      console.log("network changed: ", network);
      if (!network || !onboard) return;

      onboard.config({ networkId: network });
      defaultProvider.removeAllListeners();
      defaultProvider = new providers.InfuraProvider(network);
      setNetwork(network);
    })();
  }, [onboard, network, wallet]);

  async function readyToTransact() {
    if (!provider) {
      //#HACK if provider is set, onboard should be set.
      const walletSelected = await onboard!.walletSelect();
      if (!walletSelected) return false;
    }

    return await onboard!.walletCheck();
  }

  const disconnectWallet = () => {
    if (onboard) {
      try {
        onboard.walletReset();
      } catch (e) {
        console.error(e);
      }
      setBalance(undefined);
      setAddress(undefined);
      window.localStorage.removeItem("selectedWallet");
    }
  };

  return (
    <Web3Context.Provider
      value={[
        {
          address,
          ens,
          network,
          balance,
          wallet,
          onboard,
        },
        { ready: readyToTransact, disconnect: disconnectWallet },
      ]}
    >
      {children}
    </Web3Context.Provider>
  );
};

export default function useWeb3() {
  return useContext(Web3Context);
}
