import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface INewSmartContractData {
  dappId: string;
  contractId: string;
  method: string;
  name: string;
  methodType: string;
  apiType: string;
}

export default function useAddCustomApiMutation() {
  const queryClient = useQueryClient();
  // options?: UseQueryOptions<Dapp[], Error, TData>
  return useMutation(
    async (sc: INewSmartContractData) => {
      try {
        const result = await api.post(`dapp/${sc.dappId}/meta-api`, {
          contractId: sc.contractId,
          name: sc.name,
          apiType: sc.apiType,
          method: sc.method,
          methodType: sc.methodType,
        });
        return result.data;
      } catch (err: any) {
        throw new Error(err.response.data.error || err.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["metaApiByDappId"]);
      },
    }
  );
}
