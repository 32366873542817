import React, { useEffect, useMemo, useState } from "react";
import {
  DataCardBody,
  DataCardHeaderContainer,
  DataCardHeading,
} from "pages/home/components/DataCard";
import Breadcrumbs from "pages/home/components/Breadcrumbs";
// import TextInput from "components/inputs/TextInput";
// import Select from "components/inputs/Select";
import Logo from "assets/branding/logo.svg";
// import { FiArrowDownRight } from "react-icons/fi";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { useParams } from "react-router-dom";
import useDappQuery from "hooks/useDappQuery";
import { getNetworkDetail } from "utils/allSupportedNetwork";
import { Stats } from "hooks/useStatsQuery";
import { StatsGraph } from "hooks/useStatsGraphQuery";
import api from "api/axios";
import Table from "../components/Table";
import {
  LineChart,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import Decimal from "decimal.js";
import { formatDateXAxis, formatTooltipDate } from "utils/chartUtils";

interface IDappStatsProps {}

const DappStats: React.FunctionComponent<IDappStatsProps> = (props) => {
  const [dateSelected, setDateSelected] = useState([
    new Date(new Date().setDate(new Date().getDate() - 7)),
    new Date(),
  ]);
  const [loading, setLoading] = useState(false);
  const [statData, setStatData] = useState<Stats>();
  const [transactionList, setTransactionList] = useState<
    {
      transactionHash: string;
      transactionFee: number;
      transactionFeeInFiat: number;
      status: string;
      txCreationTime: number;
    }[]
  >([]);
  const [chart1, setChart1] = useState<StatsGraph[]>([]);
  const [chart2, setChart2] = useState<StatsGraph[]>([]);
  const [chart3, setChart3] = useState<StatsGraph[]>([]);

  const { dappId } = useParams();
  const dappQuery = useDappQuery(dappId!);

  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        const startTime = dateSelected[0].getTime() - dateSelected[0].getTimezoneOffset()*60000;
        const endTime = dateSelected[1].getTime() - dateSelected[1].getTimezoneOffset()*60000;
        setLoading(true);
        // fetch basic data
        const statDataResponse = await api.post(`/data/dapp/${dappId}`, {
          startTime,
          endTime,
        });
        setStatData(statDataResponse.data.data as Stats);

        // fetch all transactions list
        const txData = await api.post(
          `/data/dapp/${dappId}/transaction-history`,
          {
            startTime,
            endTime,
          }
        );

        // fetch graph
        let graph = await api.post(`/data/dapp/${dappId}/graph`, {
          startTime,
          endTime,
          graphType: 2,
        });
        let data = graph.data.data as StatsGraph[];
        setChart1(data);

        graph = await api.post(`/data/dapp/${dappId}/graph`, {
          startTime,
          endTime,
          graphType: 0,
        });
        data = graph.data.data as StatsGraph[];
        setChart2(data);

        graph = await api.post(`/data/dapp/${dappId}/graph`, {
          startTime,
          endTime,
          graphType: 1,
        });
        data = graph.data.data as StatsGraph[];
        setChart3(data);

        setTransactionList(txData.data.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        console.error(err);
      }
    };
    if (dappId && dateSelected && dateSelected.length > 0) fetchGraphData();
  }, [dappId, dateSelected]);

  const columns = [
    { Header: "Txn Hash", accessor: "transactionHash" },
    { Header: "Txn Fee", accessor: "transactionFee" },
    {
      Header: "Txn Fee (USD)",
      accessor: "transactionFeeInFiat",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Created On",
      accessor: "txCreationTime",
    },
  ];

  const FormatToolTip = (props: any) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <div className="custom-tooltip bg-white rounded-md border border-gray-900 p-4">
          <p className="label font-medium">{`${formatTooltipDate(props.label)}`}</p>
          {props.integerValue && 
            <p className="desc" style={{color: props.strokeColor}}>{props.payload[0].value} {props.unit}</p>
          }
          {!props.integerValue && 
            <p className="desc" style={{color: props.strokeColor}}>{props.payload[0].value.toFixed(5)} {props.unit}</p>
          }
        </div>
      );
    }
    return null;
  };

  const customTransactionList = useMemo(() => {
    if (transactionList === undefined || transactionList.length === 0)
      return [];
    return transactionList.map((data) => {
      return {
        transactionHash: (
          <a
            className="text-biconomy-orange"
            href={
              getNetworkDetail(dappQuery.data?.networkId!, "url") +
              data.transactionHash
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.transactionHash.slice(0, 5) +
              "..." +
              data.transactionHash.slice(-7)}
          </a>
        ),
        transactionFee: new Decimal(data.transactionFee)
          .div(Math.pow(10, 18))
          .toNumber()
          ?.toFixed(8),
        transactionFeeInFiat: data.transactionFeeInFiat?.toFixed(5),
        status: data.status,
        txCreationTime: new Date(data.txCreationTime)
          .toString()
          .substring(4, 15),
      };
    });
  }, [dappQuery.data?.networkId, transactionList]);

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-4xl tracking-tight my-2">
          {dappQuery.data?.dappName}
        </h1>
      </div>
      <Breadcrumbs
        crumbs={[
          { title: "Your dapps", linkTo: "/dapps" },
          {
            title: dappQuery.data?.dappName || "Your Dapp",
            linkTo: `/dapps/${dappId}`,
          },
          { title: "Statistics", linkTo: `/dapps/${dappId}/stats` },
        ]}
      />

      {/* Date Picker */}
      <hr className="bg-midnight" />
      <hr className="bg-midnight" />
      <div className="flex flex-col gap-4 mt-4 items-center	">
        <DataCardBody className="w-full bg-inherit p-1 pb-4">
          <div className="flex flex-col w-max gap-3 font-normal self-end">
            {/* <span className="text-foreground/70 uppercase text-xs font-bold pr-2">
              Date Range
            </span> */}
            <DateRangePicker
              className="bg-white/70 border-biconomy-orange/10 rounded-md"
              value={dateSelected}
              onChange={(e: any) => setDateSelected(e)}
              rangeDivider=" to "
            />
          </div>
        </DataCardBody>

        {loading ? (
          <div className="flex flex-col w-full mt-20 justify-center items-center">
            <img
              src={Logo}
              className="animate-bounce h-12 w-auto text-left"
              alt=""
            />
            <span className="m-5">Fetching data...</span>
          </div>
        ) : (
          <>
            {/* Dapp basic data */}
            <div className="grid grid-cols-3 gap-2 w-full">
              <DataCardBody className="bg-black/5 shadow-md rounded-lg">
                <DataCardHeaderContainer sepratorClassName="bg-black/10">
                  <DataCardHeading
                    title="Total Transactions"
                    className=" text-foreground/70 text-xs uppercase font-semibold"
                  />
                </DataCardHeaderContainer>
                <div className="flex text-3xl text-foreground font-mono pt-2 pb-2 mx-auto gap-5">
                  <span>{statData?.transactionCount}</span>
                </div>
              </DataCardBody>
              <DataCardBody className="bg-black/5 shadow-md rounded-lg">
                <DataCardHeaderContainer sepratorClassName="bg-black/10">
                  <DataCardHeading
                    title="Total Txn Fee (Fiat)"
                    className=" text-foreground/70 text-xs uppercase font-semibold"
                  />
                </DataCardHeaderContainer>
                <div className="flex text-2xl text-foreground font-mono pt-2 pb-2 mx-auto gap-5 items-center">
                  <span>
                    $ {statData?.totalTransactionFeeInFiat?.toFixed(3)}
                  </span>
                </div>
              </DataCardBody>

              <DataCardBody className="bg-black/5 shadow-md rounded-lg">
                <DataCardHeaderContainer sepratorClassName="bg-black/10">
                  <DataCardHeading
                    title="Total Txn Fee (Token)"
                    className=" text-foreground/70 text-xs uppercase font-semibold"
                  />
                </DataCardHeaderContainer>
                <div className="flex text-2xl text-foreground font-mono pt-2 pb-2 mx-auto gap-5 items-center">
                  <span>
                    {statData?.totalTransactionFeeInToken?.toFixed(3)}{" "}
                    {getNetworkDetail(
                      dappQuery.data?.networkId || "",
                      "currency"
                    )}
                  </span>
                </div>
              </DataCardBody>
            </div>

            {/* Dapp Graphs */}
            {!chart1 && !chart2 && !chart3 && (
              <span>No Transactions Found</span>
            )}
            <div className="grid grid-cols-1 w-full">
              {/** Transaction Count Per Day*/}
              {chart2 && chart2.length > 0 && (
                <div className="bg-white shadow-md rounded-lg my-5 pt-5 px-4">
                  <ResponsiveContainer  width="100%" height={350}>
                    <ComposedChart
                      data={chart2}
                      margin={{ top: 40, right: 30, left: 20, bottom: 30 }}
                    >
                      <CartesianGrid stroke="#e5e5e5" strokeDasharray="3" vertical={false}/>
                      <XAxis
                        dataKey="_id" 
                        tickLine={false} 
                        axisLine={{stroke: "#C7C7C7"}}
                        tickFormatter={formatDateXAxis}
                        minTickGap={30}
                        tick={{fontSize: ".8rem"}}/>

                      <YAxis 
                        axisLine={{stroke: "#C7C7C7"}} 
                        tickLine={{stroke: "#e5e5e5"}}
                        tick={{fontSize: ".8rem"}}/>

                      <Tooltip content={<FormatToolTip unit="txns" integerValue={true} strokeColor="#413ea0"/>}/>
                      <Legend />
                      <Bar
                        name="Transaction Count"
                        dataKey="transactionCount"
                        barSize={20}
                        fill="#413ea0"
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              )}

              {/** Transaction Fee In Native Currency*/}
              {chart2 && chart2.length > 0 && (
                <div className="bg-white shadow-md rounded-lg my-5 pt-5 px-4">
                  <ResponsiveContainer width="100%" height={350}>
                      <LineChart
                        data={chart2}
                        margin={{ top: 40, right: 30, left: 20, bottom: 30 }}
                      >
                        <CartesianGrid stroke="#e5e5e5" strokeDasharray="3" vertical={false}/>
                        <XAxis
                          dataKey="_id"
                          tickLine={false}
                          axisLine={{stroke: "#C7C7C7"}}
                          tickFormatter={formatDateXAxis}
                          minTickGap={30}
                          tick={{fontSize: ".8rem"}}/>

                        <YAxis 
                          axisLine={{stroke: "#C7C7C7"}} 
                          tickLine={{stroke: "#e5e5e5"}}
                          tick={{fontSize: ".8rem"}}/>

                        <Tooltip content={<FormatToolTip unit={getNetworkDetail(
                            dappQuery.data?.networkId || "",
                            "currency"
                          )} strokeColor="#82ca9d"/>} />
                        <Legend />
                        <Line
                          name={`Txn Fee in ${getNetworkDetail(
                            dappQuery.data?.networkId || "",
                            "currency"
                          )}`}
                          type="monotone"
                          dataKey="transactionFee"
                          stroke="#82ca9d"
                          strokeWidth={2}
                          dot={false}
                        />
                      </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
              
              {/** Unique User Count Per Day*/}
              {chart3 && chart3.length > 0 && (
                <div className="bg-white shadow-md rounded-lg my-5 pt-5 px-4">
                  <ResponsiveContainer width="100%" height={350}>
                    <LineChart
                      data={chart3}
                      margin={{ top: 40, right: 30, left: 20, bottom: 30 }}
                    >
                      <CartesianGrid stroke="#e5e5e5" strokeDasharray="3" vertical={false}/>
                      <XAxis 
                        dataKey="_id" 
                        tickLine={false} 
                        axisLine={{stroke: "#C7C7C7"}}
                        tickFormatter={formatDateXAxis}
                        minTickGap={30}
                        tick={{fontSize: ".8rem"}}/>

                      <YAxis 
                        axisLine={{stroke: "#C7C7C7"}} 
                        tickLine={{stroke: "#e5e5e5"}}
                        tick={{fontSize: ".8rem"}}/>

                      <Tooltip content={<FormatToolTip unit="users" integerValue={true} strokeColor="#D85111"/>}/>
                      <Legend />
                      <Line
                        type="monotone"
                        name="Unique User Count"
                        dataKey="uniqueUserCount"
                        stroke="#D85111"
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}

              {/** Average Gas Fee Per Transaction Per Day*/}
              {chart1 && chart1.length > 0 && (
                <div className="bg-white shadow-md rounded-lg my-5 pt-5 px-4">
                  <ResponsiveContainer width="100%" height={350}>
                    <LineChart
                      data={chart1}
                      margin={{ top: 40, right: 30, left: 20, bottom: 30 }}
                    >
                      <CartesianGrid stroke="#e5e5e5" strokeDasharray="3" vertical={false}/>
                      <XAxis 
                        dataKey="_id" 
                        tickLine={false} 
                        axisLine={{stroke: "#C7C7C7"}}
                        tickFormatter={formatDateXAxis}
                        minTickGap={30}
                        tick={{fontSize: ".8rem"}}/>

                      <YAxis 
                        axisLine={{stroke: "#C7C7C7"}} 
                        tickLine={{stroke: "#e5e5e5"}}
                        tick={{fontSize: ".8rem"}}/>

                      <Tooltip content={
                        <FormatToolTip 
                            unit={getNetworkDetail(dappQuery.data?.networkId || "","currency")} 
                            strokeColor="#D85111" />
                        }/>
                      <Legend />
                      <Line
                        type="monotone"
                        name={`Average ${getNetworkDetail(
                          dappQuery.data?.networkId || "",
                          "currency"
                        )} per transaction`}
                        dataKey="averageGas"
                        stroke="#D85111"
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>

            {/* Dapp transaction list */}
            <DataCardBody className="bg-black/5 shadow-md rounded-lg">
              <DataCardHeaderContainer sepratorClassName="bg-black/10">
                <DataCardHeading
                  title="Transaction History"
                  className=" text-black"
                />
              </DataCardHeaderContainer>
              <Table columns={columns} data={customTransactionList || []} />
            </DataCardBody>
          </>
        )}
      </div>
    </>
  );
};

export default DappStats;
