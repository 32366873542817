import PrimaryButton from "components/buttons/PrimaryButton";
import MultiSelect, {
  businessCategoryTypes,
} from "components/inputs/MutliSelect";
import TextArea from "components/inputs/TextArea";
import TextInput from "components/inputs/TextInput";
import Breadcrumbs from "pages/home/components/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import api from "api/axios";
import Alert from "components/Alert";
import useUserQuery from "hooks/useUserQuery";
import useEditUserMutation from "hooks/mutations/EditUserMutation";
import { Tooltip } from "components/Tooltip";

export interface IAccountEditProps {}

export function AccountEdit(props: IAccountEditProps) {
  const tabs: Array<string> = ["General Information", "Security"];

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-4xl tracking-tight my-2">Account</h1>
      </div>
      <Breadcrumbs
        crumbs={[
          { title: "Account Details", linkTo: "/account" },
          { title: "Edit", linkTo: "/account/edit" },
        ]}
      />
      <div className="flex-col py-2 gap-4 flex">
        <div className="flex gap-4 items-center font-medium">
          {tabs.map((tab) => {
            return (
              <button
                className={twMerge(
                  "rounded-md p-3 px-4",
                  tab !== selectedTab
                    ? "text-foreground-light border border-light-border hover:border hover:border-biconomy-orange/30 transition-all duration-200"
                    : "bg-foreground-lightest/10 border border-foreground-lightest/10 text-foreground/80"
                )}
                onClick={() => {
                  setSelectedTab(tab);
                }}
                key={tab}
              >
                {tab}
              </button>
            );
          })}
        </div>
        <div className="flex w-full mt-2 h-[2px] bg-light-border"></div>
      </div>
      {selectedTab === tabs[0] && <GeneralInformation />}
      {selectedTab === tabs[1] && <Security />}
    </>
  );
}

const GeneralInformation: React.FC = () => {
  const userQuery = useUserQuery();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [category, setCategory] = useState([businessCategoryTypes[0]]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  useEffect(() => {
    if (userQuery.data) {
      setValue("email", userQuery.data.adminEmail);
      setValue("username", userQuery.data.adminUserName);
      setValue("url", userQuery.data.websiteUrl);
      setValue("description", userQuery.data.description);
      setValue("twitterUsername", userQuery.data.twitterId);
      setValue("telegramId", userQuery.data.telegramId);
      setValue("discordUsername", userQuery.data.discordId);
      if (
        userQuery.data.businessCategory &&
        userQuery.data.businessCategory?.length > 0
      ) {
        const catg: {
          value: string;
          label: string;
        }[] = [];
        for (const i in userQuery.data.businessCategory) {
          for (const j in businessCategoryTypes) {
            if (
              userQuery.data.businessCategory[i] ===
              businessCategoryTypes[j].value
            ) {
              catg.push(businessCategoryTypes[j]);
            }
          }
        }
        setCategory(catg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, userQuery.data]);

  const editUserMutation = useEditUserMutation();
  const updateGeneralInfo = async (data: any) => {
    setError("");
    setSuccess("");
    if (data.email === userQuery.data?.email) {
      setError("Enter another (alternate) email address");
      return;
    }
    if (category.length === 0) {
      setError("Please select at least one category");
      return;
    }
    try {
      await editUserMutation.mutateAsync({
        // adminFullName: data.adminFullName,
        adminUserName: data.username,
        websiteUrl: data.url,
        businessCategory: category.map((data) => data.value),
        description: data.description,
        alternateEmail: data.email,
        telegramId: data.telegramId,
        twitterId: data.twitterUsername,
        discordId: data.discordUsername,
      });
      setSuccess("Account details updated successfully");
    } catch (error: any) {
      console.error(error);
      setError(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(updateGeneralInfo)}>
      <div className="grid grid-cols-2 py-4 gap-12">
        <div className="flex-col flex gap-6">
          <div className="flex flex-col gap-2">
            <label className="font-medium text-foreground">
              <Tooltip message="Email that would help us contact bd/finance team">
                Alternate email
                <img src="/icons/info.svg" className="w-3" alt="" />
              </Tooltip>
            </label>
            <TextInput
              placeholder="Email"
              className="w-full"
              type="email"
              registry={register("email", {
                required: "Please enter your email address",
                pattern: {
                  value:
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Invalid email address",
                },
              })}
            ></TextInput>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-medium text-foreground">Username</label>
            <TextInput
              placeholder="Your username"
              type="username"
              registry={register("username", { required: true })}
            ></TextInput>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-medium text-foreground">Website URL</label>
            <TextInput
              placeholder="Your dapp URL"
              registry={register("url", {
                required: "Please enter your URL address",
                pattern: {
                  value:
                    // eslint-disable-next-line no-useless-escape
                    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/,
                  message: "Invalid URL address",
                },
              })}
            ></TextInput>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-medium text-foreground">Telegram Id *</label>
            <TextInput
              placeholder="biconauts"
              className="w-full"
              type="telegramId"
              registry={register("telegramId", {
                required: "Please enter the Telegram Id",
                pattern: {
                  value:
                    // eslint-disable-next-line no-useless-escape
                    /^[a-zA-Z0-9_]{1,15}$/,
                  message: "Invalid telegram id",
                },
              })}
            ></TextInput>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-medium text-foreground">
              Twitter Username *
            </label>
            <TextInput
              placeholder="biconomy"
              type="twitterUsername"
              registry={register("twitterUsername", {
                required: "Please enter your Twitter username",
                pattern: {
                  value:
                    // eslint-disable-next-line no-useless-escape
                    /^[a-zA-Z0-9_]{1,15}$/,
                  message: "Invalid Twitter Username",
                },
              })}
            ></TextInput>
          </div>
        </div>
        <div className="flex-col gap-6 flex">
          <div className="flex-col gap-2 flex">
            <h3 className="font-medium text-foreground">Business Category</h3>
            <MultiSelect category={category} setCategory={setCategory} />
          </div>
          <div className="flex-col gap-2 flex flex-grow">
            <h3 className="font-medium text-foreground">Description</h3>
            <TextArea
              className="flex-grow"
              placeholder="Write here..."
              registry={register("description", { required: true })}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-medium text-foreground">
              Discord Username
            </label>
            <TextInput
              placeholder="bico#1234"
              type="discordUsername"
              registry={register("discordUsername", {
                required: false,
                pattern: {
                  value:
                    // eslint-disable-next-line no-useless-escape
                    /.+#\d{4}/,
                  message: "Invalid Discord Id",
                },
              })}
            ></TextInput>
          </div>
        </div>
      </div>
      <div className="flex-col">
        {errors.adminName && <Alert color="error" message="Admin name empty" />}
        {errors.userName && <Alert color="error" message="User name empty" />}
        {errors.websiteUrl && <Alert color="error" message="Website url req" />}
        {errors.description && (
          <Alert color="error" message="Description is empty" />
        )}
        {error && <Alert color="error" message={error} />}
        {success && <Alert color="success" message={success} />}
      </div>
      <div className="py-2 grid place-items-center">
        <PrimaryButton className="py-2.5 px-10" type="submit">
          Save
        </PrimaryButton>
      </div>
    </form>
  );
};

const Security: React.FC = () => {
  const userQuery = useUserQuery();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { register, handleSubmit } = useForm();

  const updatePassword = async (data: any) => {
    setError("");
    if (data.newPassword !== data.confirmNewPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      await api.patch("/user/update-password", {
        email: userQuery.data?.email,
        oldPassword: data.oldPassword,
        newPassword: data.confirmNewPassword,
      });
      setSuccess("Password Updated Successfully");
    } catch (err: any) {
      console.error(err);
      setError(err.response.data.error || err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(updatePassword)}>
      <div className="max-w-lg py-8">
        <div className="flex-col flex gap-6">
          <div className="flex-col gap-2 flex">
            <h3 className="font-medium text-foreground">Old Password</h3>
            <TextInput
              className="w-full"
              type="password"
              registry={register("oldPassword", { required: true })}
            />
          </div>
          <div className="flex-col gap-2 flex">
            <h3 className="font-medium text-foreground">New Password</h3>
            <TextInput
              className="w-full"
              type="password"
              registry={register("newPassword", { required: true })}
            />
          </div>
          <div className="flex-col gap-2 flex">
            <h3 className="font-medium text-foreground">
              Confirm New Password
            </h3>
            <TextInput
              className="w-full"
              type="password"
              registry={register("confirmNewPassword", { required: true })}
            />
          </div>
        </div>
      </div>
      {error && <Alert color="error" message={error} />}
      {success && <Alert color="success" message={success} />}
      <div className="py-12">
        <PrimaryButton className="py-3 px-12" type="submit">
          Save
        </PrimaryButton>
      </div>
    </form>
  );
};
