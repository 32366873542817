import Onboard from "bnc-onboard";
import { Subscriptions } from "bnc-onboard/dist/src/interfaces";

const networkId = 1;
const infuraId = process.env.REACT_APP_INFURA_ID;
const rpcUrl = `https://mainnet.infura.io/v3/${infuraId}`;
// const dappId = process.env.REACT_APP_DAPP_ID;

export function initOnboard(subscriptions: Subscriptions) {
  return Onboard({
    // dappId,
    hideBranding: true,
    networkId,
    // apiUrl,
    darkMode: true,
    subscriptions,
    walletSelect: {
      wallets: [
        { walletName: "metamask", preferred: true },
        {
          walletName: "walletConnect",
          preferred: true,
          infuraKey: infuraId,
        },
        {
          walletName: "trezor",
          appUrl: "https://memenumbers.com",
          email: "",
          rpcUrl,
        },
        {
          walletName: "ledger",
          rpcUrl,
        },
        { walletName: "coinbase" },
        { walletName: "status" },
        { walletName: "walletLink", rpcUrl },
        {
          walletName: "portis",
          apiKey: "b2b7586f-2b1e-4c30-a7fb-c2d1533b153b",
        },
      ],
    },
    walletCheck: [
      { checkName: "derivationPath" },
      { checkName: "connect" },
      { checkName: "accounts" },
      { checkName: "network" },
      { checkName: "balance", minimumBalance: "100000" },
    ],
  });
}
