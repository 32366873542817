import useUserQuery from "hooks/useUserQuery";
import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ResetPassword from "./ResetPassword";
import UpdatePassword from "./UpdatePassword";

const Auth: React.FC = () => {
  const userQuery = useUserQuery();
  const location = useLocation();

  if (userQuery.data) {
    return <Navigate to="/onboarding" replace state={{ from: location }} />;
  }

  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="*" element={<Navigate to="/auth/signin" replace />} />
    </Routes>
  );
};

export default Auth;
