import React, { useCallback, useEffect, useState } from "react";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";
import api from "api/axios";
import Alert from "components/Alert";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";

interface IVerifyProps {}

const Email: React.FC<IVerifyProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // if has auth token
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const sendToken = useCallback(async () => {
    setError("");
    if (!token) {
      setError("Invalid token");
      return;
    }
    try {
      setLoading(true);
      await api.get(`user/email/verify?token=${token}`);
      setLoading(false);
      setSuccess("Email verified successfully...");
      queryClient.invalidateQueries(["user"]);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      navigate("/");
    } catch (err: any) {
      setLoading(false);
      console.error(err);
      setError(err.response.data.error || err.message);
    }
  }, [navigate, queryClient, token]);

  useEffect(() => {
    if (token) {
      sendToken();
    } else {
      setError("No valid token found");
    }
  }, [navigate, sendToken, token]);

  return (
    <main className="flex flex-grow justify-center">
      <div className="flex flex-col max-w-sm flex-grow mx-3 pt-36 gap-5">
        <img src={BiconomyWordmark} className="w-[50%] mx-auto mb-14" alt="" />
        <div className="flex justify-between items-baseline">
          <h1 className="font-semibold text-lg">Verify your email</h1>
        </div>
        <div className="text-sm text-foreground">
          Please wait while we are verifying your email.
        </div>
        {loading && (
          <div
            style={{ borderTopColor: "transparent" }}
            className="w-6 h-6 border-4 border-biconomy-orange border-dashed rounded-full animate-spin mr-2"
          ></div>
        )}
        {error && <Alert color="error" message={error} />}
        {success && <Alert color="success" message={success} />}
      </div>
    </main>
  );
};

export default Email;
