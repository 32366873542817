import React, { useState } from "react";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";
import PrimaryButton from "components/buttons/PrimaryButton";
import Alert from "components/Alert";
import { Link } from "react-router-dom";
import TextInput from "components/inputs/TextInput";
import { useForm } from "react-hook-form";
import { useAuth } from "context/Auth";

interface IForgetPasswordProps {}

const ForgetPassword: React.FC<IForgetPasswordProps> = () => {
  // const navigate = useNavigate();
  const { resetPassword } = useAuth();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <main className="flex flex-grow justify-center">
      <form className="flex flex-col max-w-sm flex-grow mx-3 pt-36 gap-5">
        <img src={BiconomyWordmark} className="w-[50%] mx-auto mb-14" alt="" />
        <div className="flex justify-between items-baseline">
          <h1 className="font-semibold text-lg">Reset Password</h1>
          <span className="text-sm text-foreground-light font-normal flex gap-1">
            Remember password?
            <Link to="../signin">
              <span className="text-biconomy-orange">Sign in</span>
            </Link>
          </span>
        </div>
        <TextInput
          placeholder="Email"
          className="w-full"
          type="email"
          registry={register("email", {
            required: "Please enter your email address",
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Invalid email address",
            },
          })}
        />
        {errors.email && <Alert color="error" message={errors.email.message} />}
        {error && <Alert color="error" message={error} />}

        <PrimaryButton
          className="w-full py-3.5"
          disabled={loading}
          onClick={handleSubmit(async (data) => {
            setError("");
            setSuccess(false);
            try {
              setLoading(true);
              await resetPassword(data.email);
              setLoading(false);
              setSuccess(true);
              // navigate("/");
            } catch (err: any) {
              setLoading(false);
              console.error(err);
              setError(err.response.data.error || err.message);
            }
          })}
          type="submit"
        >
          Send Me The Magic Link
        </PrimaryButton>

        {success && (
          <Alert color="success">
            <div className="text-sm text-foreground-light">
              Email sent successfully!{" "}
              <span className="text-biconomy-orange">Check mail</span>
            </div>
          </Alert>
        )}
      </form>
    </main>
  );
};

export default ForgetPassword;
