import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

type Path = {
  title: string;
  linkTo: string;
};

interface IBreadcrumbsProps {
  crumbs: Path[];
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ crumbs }) => {
  return (
    <div className="flex gap-2 py-4 items-center">
      {crumbs.map((crumb, index) => {
        if (index !== crumbs.length - 1) {
          return (
            <>
              <Link to={crumb.linkTo}>
                <span className="text-foreground-light">{crumb.title}</span>
              </Link>
              <span className="text-foreground-lightest/50">
                <AiOutlineRight />
              </span>
            </>
          );
        } else {
          return (
            <Link to={crumb.linkTo} key={index}>
              <span className="text-biconomy-orange/70">{crumb.title}</span>
            </Link>
          );
        }
      })}
    </div>
  );
};

export default Breadcrumbs;
