import React, { useEffect, useMemo, useState, useCallback } from "react";
import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";

import TextInput from "components/inputs/TextInput";
import TextArea from "components/inputs/TextArea";
import Select, { Option } from "components/inputs/Select";
import useContractABIQuery from "hooks/useContractABI";
import useAddSmartContractMutation from "hooks/mutations/addSmartContractMutation";
import useAddSCWSmartContractMutation from "hooks/mutations/addSCWSmartContractMutation";
import Alert from "components/Alert";
import PrimaryButton from "components/buttons/PrimaryButton";
import {
  DataCardBody,
  DataCardHeaderContainer,
  DataCardHeading,
} from "pages/home/components/DataCard";
import Table from "pages/home/layouts/dapps/details/components/Table";
import useDappSmartContractsQuery, {
  SmartContract,
} from "hooks/useDappSmartContractsQuery";
import useDeleteSmartContractMutation from "hooks/mutations/deleteSmartContractMutation";
import useDappQuery from "hooks/useDappQuery";
import Logo from "assets/branding/logo.svg";
import { twMerge } from "tailwind-merge";
import config from "utils/config";

interface ISmartContractsProps {
  networkId: string;
}

const SmartContracts: React.FC<ISmartContractsProps> = ({ networkId }) => {
  // fetch and update smart contract list to state
  const { dappId } = useParams();
  const dappSmartContractsQuery = useDappSmartContractsQuery(dappId!);
  const [smartContracts, setSmartContracts] = useState<SmartContract[]>([]);
  useEffect(() => {
    if (dappSmartContractsQuery.data) {
      setSmartContracts(dappSmartContractsQuery.data);
    }
  }, [dappSmartContractsQuery.data]);

  // delete smart contract logic
  const deleteSmartContract = useDeleteSmartContractMutation();
  const deleteContract = useCallback(
    async (contractId: string) => {
      deleteSmartContract.mutate({ contractId });
    },
    [deleteSmartContract]
  );

  // edit smart contract aaray row to rencder delete action
  const customSmartContracts = useMemo(
    () =>
      smartContracts.map((smartContract) => {
        return {
          ...smartContract,
          address:
            smartContract.address === "na for biconomy scw"
              ? "N/A for Biconomy SCW"
              : smartContract.address === "na for gnosis scw"
              ? "N/A for Gnosis SCW"
              : smartContract.address,
          action: (
            <div className="flex justify-center">
              <AiOutlineDelete
                className="text-gray-600 cursor-pointer"
                onClick={() => deleteContract(smartContract._id)}
              />
            </div>
          ),
        };
      }),
    [deleteContract, smartContracts]
  );

  // const [showAddForm, setShowAddForm] = useState(false);
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Address", accessor: "address" },
    { Header: "Type", accessor: "type" },
    {
      Header: "Meta Tx Type",
      accessor: "metaTransactionType",
      cellClassName: "whitespace-nowrap",
    },
    {
      collapse: true,
      Header: "Action",
      cellClassName: "text-center",
      accessor: "action",
    },
  ];

  const tabs: Array<string> = ["Smart Contract (SC)"];
  if (
    config.gnosisWalletSupportedNetworks.includes(networkId) ||
    config.biconomyWalletSupportedNetworks.includes(networkId)
  ) {
    tabs.push("Smart Contract Wallet (SCW)");
  }
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);

  return (
    <>
      <div className="flex-col flex">
        <div className="flex gap-4 pb-6 items-center font-medium">
          {tabs.map((tab) => {
            return (
              <button
                className={twMerge(
                  "rounded-md p-3 px-4",
                  tab !== selectedTab
                    ? "text-foreground-light border border-light-border hover:border hover:border-biconomy-orange/30 transition-all duration-200"
                    : "bg-foreground-lightest/10 border border-foreground-lightest/10 text-foreground/80"
                )}
                onClick={() => {
                  setSelectedTab(tab);
                }}
                key={tab}
              >
                {tab}
              </button>
            );
          })}
        </div>
        {/* <div className="flex w-full h-[2px] bg-light-border"></div> */}
        {selectedTab === tabs[0] && <AddSc />}
        {selectedTab === tabs[1] && <AddScw />}
      </div>
      <DataCardBody className="bg-black/5">
        <DataCardHeaderContainer sepratorClassName="bg-black/10">
          <DataCardHeading title="Smart Contracts" className=" text-black" />
          <div>
            {/* <PrimaryButton
              onClick={() => setShowAddForm(!showAddForm)}
              className="bg-white px-4 py-2 text-black text-sm flex gap-2 font-medium"
            >
              <span>{showAddForm ? "Hide" : "Add"}</span>
              <span>Add</span>
              <MdOutlineModeEditOutline className="h-4 w-auto" />
            </PrimaryButton> */}
          </div>
        </DataCardHeaderContainer>
        {!dappSmartContractsQuery.isFetchedAfterMount && (
          <div className="flex flex-col w-full mt-10 justify-center items-center">
            <img
              src={Logo}
              className="animate-bounce h-12 w-auto text-left"
              alt=""
            />
            <span className="m-5">Fetching data...</span>
          </div>
        )}
        {dappSmartContractsQuery.isFetchedAfterMount && (
          <Table columns={columns} data={customSmartContracts || []} />
        )}
      </DataCardBody>
      {/* {showAddForm && <AddSc setShowAddForm={setShowAddForm} />} */}
    </>
  );
};

const AddScw: React.FC = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { dappId } = useParams();
  const dappQuery = useDappQuery(dappId as string);
  const addScwMutation = useAddSCWSmartContractMutation();
  const scwOptions = [];
  if (
    config.gnosisWalletSupportedNetworks.includes(dappQuery.data?.networkId!)
  ) {
    scwOptions.push({ title: "Gnosis", value: "gnosis" });
  }
  if (
    config.biconomyWalletSupportedNetworks.includes(dappQuery.data?.networkId!)
  ) {
    scwOptions.push({ title: "Biconomy Wallet", value: "biconomy" });
  }

  const [secWallet, setSecWallet] = useState<Option>(scwOptions[0]);

  const handleAddScw = async (e: any) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    // mutate api for contract
    try {
      const data = await addScwMutation.mutateAsync({
        dappId: dappId!,
        secwallet: secWallet.value,
      });
      if (data.code === 200) setSuccess("SCW successfully added.");
      if (data.code === 149) setSuccess("SCW successfully updated.");
      if (data.code === 405) setSuccess("SCW already exist.");
      else {
        console.error(data);
      }
    } catch (err: any) {
      console.error(err);
      setError(err.response.data.error || err.message);
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-12">
        <div className="flex-col flex gap-6">
          <div className="flex-col gap-2 flex">
            <h3 className="font-medium text-foreground">
              Select External Contract Wallet
            </h3>
            <Select
              buttonClassName="text-foreground"
              options={scwOptions}
              selected={secWallet}
              onChange={(e: any) => setSecWallet(e)}
            />
          </div>
        </div>
      </div>
      <br />
      {error && <Alert color="error" message={error} />}
      {success && <Alert color="success" message={success} />}
      <div className="py-8 grid place-items-center">
        <PrimaryButton className="py-3 px-12" onClick={handleAddScw}>
          Add
        </PrimaryButton>
      </div>
    </>
  );
};

const AddSc: React.FC = () => {
  const [name, setName] = useState("");
  const [abi, setAbi] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const metaTxOptions = [
    { title: "Custom", value: "DEFAULT" },
    { title: "Trusted Forwarder", value: "TRUSTED_FORWARDER" },
    { title: "ERC20 Forwarder", value: "ERC20_FORWARDER" },
  ];
  const [metaTxType, setMetaTxType] = useState<Option>(metaTxOptions[1]);
  const { dappId } = useParams();
  const dappQuery = useDappQuery(dappId as string);
  const addScMutation = useAddSmartContractMutation();
  const abiQuery = useContractABIQuery(
    contractAddress,
    dappQuery.data?.networkId!
  );

  useEffect(() => {
    if (abiQuery.data) {
      setAbi(abiQuery.data);
    }
  }, [abiQuery.data]);

  const handleAdd = async (e: any) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    // validate address
    if (!ethers.utils.isAddress(contractAddress)) {
      setError("Invalid contract address");
      return;
    }
    // validate name
    if (name === "") {
      setError("Name is required");
      return;
    }
    // validate abi
    if (abi) {
      try {
        const abiParsed = JSON.parse(abi);
        if (abiParsed.length && abiParsed.length > 0) {
          setError("");
        } else {
          setError("Empty abi array");
          return;
        }
      } catch (e: any) {
        setError("Invalid abi");
        console.error("Invalid abi", e);
        return;
      }
    } else {
      setError("ABi is required");
      return;
    }
    // mutate api for contract
    try {
      const { data } = await addScMutation.mutateAsync({
        abi,
        address: contractAddress,
        dappId: dappId!,
        name,
        metaTxType: metaTxType.value,
      });
      if (data.code === 405) {
        setError("Smart contract already exists with this address");
      } else if (data.code === 200) {
        setSuccess("Smart contract successfully added.");
      } else setSuccess(data.log);
    } catch (err: any) {
      console.error(err);
      setError(err.response.data.error || err.message);
    }
  };

  return (
    <form>
      <div className="grid grid-cols-2 gap-12">
        <div className="flex-col flex gap-6">
          <div className="flex-col gap-2 flex">
            <h3 className="font-medium text-foreground">Name</h3>
            <TextInput
              className="w-full"
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              placeholder="Enter Smart Contract Name"
            />
          </div>
          <div className="flex-col gap-2 flex">
            <h3 className="font-medium text-foreground">Address</h3>
            <TextInput
              className="w-full"
              value={contractAddress}
              onChange={(e: any) => setContractAddress(e.target.value)}
              placeholder="Enter Smart Contract Address"
            />
          </div>
          <div className="flex-col gap-2 flex">
            <h3 className="font-medium text-foreground">
              Meta Transaction Type
            </h3>
            <Select
              options={metaTxOptions}
              selected={metaTxType}
              onChange={(newSelectedNetwork: any) =>
                setMetaTxType(newSelectedNetwork)
              }
            />
          </div>
        </div>
        <div className="flex-col gap-6 flex">
          <div className="flex-col gap-2 flex flex-grow">
            <h3 className="font-medium text-foreground">
              Application Binary Interface (ABI)
            </h3>
            <TextArea
              className="flex-grow font-mono font-normal"
              value={abi}
              onChange={(e) => {
                setAbi(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <br />
      {error && <Alert color="error" message={error} />}
      {success && <Alert color="success" message={success} />}
      <div className="py-8 grid place-items-center">
        <PrimaryButton className="py-3 px-12" onClick={handleAdd}>
          Add
        </PrimaryButton>
      </div>
    </form>
  );
};

export default SmartContracts;
