import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface INewDappData {
  dappName: string;
  networkId: string;
}

export default function useCreateDappMutation() {
  const queryClient = useQueryClient();
  // options?: UseQueryOptions<Dapp[], Error, TData>
  return useMutation(
    async (dapp: INewDappData) => {
      try {
        await api.post("/dapp", {
          dappName: dapp.dappName,
          networkId: dapp.networkId,
          isMaticWidgetSupported: false,
        });
      } catch (err: any) {
        throw new Error(err.response.data.error || err.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dapps"]);
      },
    }
  );
}
