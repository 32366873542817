import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface IDappData {
  dappId: string;
}

export default function useDeleteDappMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dappId }: IDappData) => {
      try {
        const result = await api.delete(`/dapp/${dappId}`);
        return result.data;
      } catch (err: any) {
        console.error(err);
        throw Error(err);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dapps"]);
      },
    }
  );
}
