/**
 * Context for changing network type between mainnet and testnet.
 */

import React from "react";

interface INetworkTypeContext {
  networkType: String;
  setNetworkType: (newNetworkType: string) => void;
}

const NetworkTypeContext = React.createContext<INetworkTypeContext | undefined>(
  undefined
);
NetworkTypeContext.displayName = "NetworkContext";

function NetworkTypeProvider(props: any) {
  const [networkType, setNetworkType] = React.useState("mainnet");

  return (
    <NetworkTypeContext.Provider
      value={{ networkType, setNetworkType }}
      {...props}
    />
  );
}

function useNetworkType() {
  const context = React.useContext(NetworkTypeContext);
  if (context === undefined) {
    throw new Error(`useNetworkType must be used within a NetworkTypeProvider`);
  }
  return context;
}

export { NetworkTypeProvider, useNetworkType };
