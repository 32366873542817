import api from "api/axios";
import {
  useMutation, useQueryClient
} from "react-query";

interface INewSmartContractData {
  name: string;
  address: string;
  dappId: string;
  abi: string;
  metaTxType: string;
}

export default function useAddSmartContractMutation() {
  const queryClient = useQueryClient();
  // options?: UseQueryOptions<Dapp[], Error, TData>
  return useMutation(
    async (sc: INewSmartContractData) => {
      const res = await api.post(`/dapp/${sc.dappId}/smart-contract`, {
        name: sc.name,
        address: sc.address,
        abi: sc.abi,
        type: "SC",
        walletType: "NONE",
        metaTransactionType: sc.metaTxType,
      });
      return res;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["smartContractsByDappId"]);
      },
    }
  );
}
