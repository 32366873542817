import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { getIcon } from "assets/icons/networks";
import {
  DataCardBody,
  DataCardHeaderContainer,
  LabelledDataField,
  LabelledDataFieldContainer,
} from "pages/home/components/DataCard";
import { MdOutlineCopyAll } from "react-icons/md";
import useDappQuery from "hooks/useDappQuery";
import { useParams } from "react-router-dom";
import { getNetworkDetail } from "utils/allSupportedNetwork";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "components/Tooltip";
import { twMerge } from "tailwind-merge";
import { AiFillCloseCircle } from "react-icons/ai";
import { Dapp } from "hooks/useDappsListQuery";

interface IDappInfroProps {
  dappData: Dapp;
}

const notify = () =>
  toast.custom(
    (t) => (
      <div
        className={twMerge(
          "notificationWrapper",
          t.visible ? "top-0" : "-top-96"
        )}
      >
        <h1>Copied to clipboard</h1>
        <div className="closeIcon" onClick={() => toast.dismiss(t.id)}>
          <AiFillCloseCircle />
        </div>
      </div>
    ),
    { id: "unique-notification", position: "top-right" }
  );

const DappDetailedInformation: React.FC<IDappInfroProps> = ({ dappData }) => {
  const { dappId } = useParams();
  const dappQuery = useDappQuery(dappId as string);

  return (
    <>
      <DataCardBody>
        <div className="absolute right-1 top-1 w-14 h-14 rounded-md rounded-tr-[1.25rem] bg-white flex items-center justify-center">
          <img src={getIcon(dappQuery.data?.networkId || "")} alt="" />
        </div>
        <DataCardHeaderContainer className="pr-12">
          <div className="text-biconomy-orange text-2xl truncate w-36">
            {dappQuery.data?.dappName}
          </div>
          <div className="flex gap-6">
            <div className="flex gap-2">
              <span className="text-sm font-light">Funding Key:</span>
              <div className="flex gap-1">
                <span className="text-sm">{dappData?.fundingKey}</span>
                <CopyToClipboard text={dappData?.fundingKey.toString()} onCopy={notify}>
                  <MdOutlineCopyAll className="mt-0.5 cursor-pointer" />
                </CopyToClipboard>
              </div>
            </div>
            <div className="flex gap-2">
              <span className="text-sm font-light">API Key</span>
              <div className="flex gap-1">
                <span className="text-sm trauncate">
                  {dappQuery.data?.apiKey
                    ? dappQuery.data?.apiKey.slice(0, 4) +
                      "..." +
                      dappQuery.data?.apiKey.slice(-4)
                    : ""}
                </span>
                <CopyToClipboard text={dappQuery.data?.apiKey!} onCopy={notify}>
                  <MdOutlineCopyAll className="mt-0.5 cursor-pointer" />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </DataCardHeaderContainer>
        <LabelledDataFieldContainer>
          <LabelledDataField
            label="Txns this month"
            data={dappData?.transactionCountThisMonth}
          />
          <LabelledDataField
            label="Network"
            data={getNetworkDetail(dappQuery.data?.networkId!, "title")}
            isRightAligned
          />
        </LabelledDataFieldContainer>
        <LabelledDataFieldContainer>
          <LabelledDataField
            label="Txns in total"
            data={dappData?.transactionCountInTotal}
          />
          <LabelledDataField
            label="Registered on"
            data={new Date(dappQuery.data?.createdOn as string)
              .toString()
              .substring(4, 15)}
            isRightAligned
          />
        </LabelledDataFieldContainer>
        <LabelledDataFieldContainer>
          <LabelledDataField
            label="Gas Balance"
            data={`${dappData?.gasTankBalance.toFixed(3)} ${getNetworkDetail(
              dappQuery.data?.networkId!,
              "currency"
            )}`}
          />
        </LabelledDataFieldContainer>
        <LabelledDataFieldContainer>
          <div className="flex flex-col gap-1">
            <span className="flex text-foreground text-xs font-semibold">
              <Tooltip message="Gas Depletion Rate">
                GDR
                <img src="/icons/info.svg" className="w-3" alt="" />
              </Tooltip>
            </span>
            <span className="text-3xl font-extralight tracking-tight">
              {`${dappData?.gasTankDepletionRate.toFixed(3)} ${getNetworkDetail(
                dappQuery.data?.networkId!,
                "currency"
              )} / hr`}
            </span>
          </div>
        </LabelledDataFieldContainer>
        <Toaster />
      </DataCardBody>
    </>
  );
};

export default DappDetailedInformation;
