import React from "react";
import { ActionBarProvider } from "./ActionBar";
import { AuthProvider } from "./Auth";
import { NetworkTypeProvider } from "./network-type";

export const AppProviders: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <NetworkTypeProvider>
        <ActionBarProvider>{children}</ActionBarProvider>
      </NetworkTypeProvider>
    </AuthProvider>
  );
};

export default AppProviders;
