import axios from "axios";
// import createAuthRefreshInterceptor from "axios-auth-refresh";

const defaultOptions = {
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    "X-Content-Type-Options": "nosniff",
    "X-Frame-Options": "DENY",
    "X-Xss-Protection": "1; mode=block",
    "X-Powered-By": "PHP",
    "X-UA-Compatible": "IE=edge",
  },
};

axios.defaults.withCredentials = true;

// Create instance
export const api = axios.create(defaultOptions);

// Function that will be called to refresh authorization
// const refreshAuthLogic = (failedRequest: any) =>
//   axios
//     .post("https://www.example.com/auth/token/refresh", {
//       refresh_token: localStorage.getItem("refresh_token"),
//     })
//     .then((tokenRefreshResponse) => {
//       localStorage.setItem("access_token", tokenRefreshResponse.data.token);
//       failedRequest.response.config.headers["Authorization"] =
//         "Bearer " + tokenRefreshResponse.data.token;
//       return Promise.resolve();
//     });

// createAuthRefreshInterceptor(api, refreshAuthLogic);
export default api;
