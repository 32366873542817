import Alert from "components/Alert";
import ExitActionBarButton from "components/buttons/ExitActionBarButton";
import PrimaryButton from "components/buttons/PrimaryButton";
import TextInput from "components/inputs/TextInput";
import useEditDappConfigMutation from "hooks/mutations/editDappConfig";
import React from "react";

const AddDappConfig: React.FC<{ dappId: string; allowedDomains: string[] }> = ({
  dappId,
  allowedDomains,
}) => {
  const [newUrl, setNewUrl] = React.useState("");
  const [error, setError] = React.useState("");

  const editDappConfig = useEditDappConfigMutation();
  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-6">
          <h1 className="text-xm font-bold">Configure</h1>

          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Add Website Url</label>
            <TextInput
              type="url"
              placeholder="http://"
              value={newUrl}
              onChange={(e: any) => setNewUrl(e.target.value)}
            />
          </div>

          {error && <Alert color="error" message={error} />}
          {editDappConfig.isError && (
            <Alert
              color="error"
              message={(editDappConfig.error as Error).message}
            />
          )}
          {editDappConfig.isSuccess && (
            <Alert color="success" message="Updated Successfully" />
          )}

          <PrimaryButton
            className="py-3"
            onClick={() => {
              setError("");
              // console.log("Saving config url 💾");
              editDappConfig.mutate({
                dappId: dappId,
                allowedDomains: [...allowedDomains, newUrl],
              });
            }}
          >
            Save
          </PrimaryButton>
        </div>

        <ExitActionBarButton />
      </div>
    </>
  );
};

export default AddDappConfig;
