import React, { useState } from "react";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";
import PrimaryButton from "components/buttons/PrimaryButton";
import useUserQuery from "hooks/useUserQuery";
import api from "api/axios";
import Alert from "components/Alert";
import { useNavigate } from "react-router-dom";

interface IVerifyProps {}

const Verify: React.FC<IVerifyProps> = (props) => {
  const user = useUserQuery();

  // if not logged in redirect to auth
  const navigate = useNavigate();
  if (!user.data) {
    navigate("/");
  }
  if (user.data && !(user.data.status === 0)) {
    navigate("/");
  }

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const sendEmail = async (e: any) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    if (!user.data?.email) setError("Email is req");
    try {
      setLoading(true);
      await api.post("user/email/verify", {
        email: user.data?.email,
      });
      setLoading(false);
      setSuccess("Email Sent Successfully, Check your email.");
    } catch (err: any) {
      setLoading(false);
      console.error(err);
      setError(err.response.data.error || err.message);
    }
  };

  return (
    <main className="flex flex-grow justify-center">
      <div className="flex flex-col max-w-sm flex-grow mx-3 pt-36 gap-5">
        <img src={BiconomyWordmark} className="w-[50%] mx-auto mb-14" alt="" />
        <div className="flex justify-between items-baseline">
          <h1 className="font-semibold text-lg">Verify your email</h1>
          <span className="text-sm text-foreground-light font-normal">
            {/* Bored? <span className="text-biconomy-orange">Do it later</span> */}
          </span>
        </div>
        <div className="text-sm text-foreground">
          You have entered the following email address:
          <div className="text-biconomy-orange font-medium py-2">
            {user.data?.email}
          </div>
        </div>
        <PrimaryButton onClick={sendEmail} disabled={loading}>
          <span className="py-3.5">Send Me The Magic Link</span>
        </PrimaryButton>

        {/* <div className="text-sm text-foreground-light">
          Not the correct email address?{" "}
          <span className="text-biconomy-orange"> Change it</span>
        </div> */}

        {error && <Alert color="error" message={error} />}
        {success && <Alert color="success" message={success} />}
      </div>
    </main>
  );
};

export default Verify;
