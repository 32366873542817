import * as React from "react";
import DappDetailedInformation from "../components/DappDetailedInformation";
import { Dapp } from "hooks/useDappsListQuery";

interface IOverviewProps {
  dappData: Dapp;
}

const Overview: React.FunctionComponent<IOverviewProps> = ({ dappData }) => {
  return <DappDetailedInformation dappData={dappData} />;
};

export default Overview;
