import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import AddContract from "./AddContract";
import DappDetails from "./details";
import DappList from "./List";

export interface IDappstProps {}

export default function Dapps(props: IDappstProps) {
  return (
    <Routes>
      <Route path="/" element={<DappList />} />
      <Route path="/:dappId/*" element={<DappDetails />} />
      {/* <Route path="/:dappId/add-contract" element={<AddContract />} /> */}
      <Route path="*" element={<Navigate to="/dapps/" replace />} />
    </Routes>
  );
}
