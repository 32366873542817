import React, { useEffect } from "react";
import { useActionBar } from "context/ActionBar";
import { Route, Routes } from "react-router-dom";
import { AccountEdit } from "./Edit";
import { AccountOverview } from "./Overview";
import ReGenerateToken from "./components/ReGenerateToken";

export interface IAccountProps {}

export default function Account(props: IAccountProps) {
  const { setCurrentNode } = useActionBar();
  useEffect(() => {
    setCurrentNode(<ReGenerateToken />);
    return () => {
      setCurrentNode(undefined);
    };
  }, [setCurrentNode]);

  return (
    <Routes>
      <Route path="/overview" element={<AccountOverview />} />
      <Route path="/edit" element={<AccountEdit />} />
      <Route path="*" element={<AccountOverview />} />
    </Routes>
  );
}
