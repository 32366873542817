import { useQuery, UseQueryOptions } from "react-query";
import api from "api/axios";

export type User =
  | {
      email: string;
      userId: string;
      isEmailVerified: boolean;
      authToken: string;
      status: number;
      createdOn: number;
      updatedOn: number;
      adminFullName?: string;
      adminUserName?: string;
      websiteUrl?: string;
      businessCategory?: Array<string>;
      description?: string;
      adminEmail?: string;
      telegramId?: string;
      twitterId?: string;
      discordId?: string;
      isOnboarded?: boolean;
    }
  | undefined;

export default function useUserQuery(options?: UseQueryOptions<User>) {
  return useQuery<User>(
    "user",
    async () => {
      try {
        const userResponse = await api.get("/user/account");
        return userResponse.data.data as User;
      } catch (e) {
        return undefined;
      }
    },
    {
      staleTime: Infinity,
      retry: 3,
      retryOnMount: false,
      // ...options,
    }
  );
}
