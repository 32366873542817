import { useQuery, UseQueryOptions } from "react-query";
import api from "api/axios";

export type SmartContract = {
  _id: string;
  name: string;
  dappId: string;
  address: string;
  abi: string;
  type: string;
};

export default function useDappSmartContractsQuery<TData = SmartContract[]>(
  dappId: string,
  options?: UseQueryOptions<SmartContract[], Error, TData>
) {
  return useQuery<SmartContract[], Error, TData>(
    ["smartContractsByDappId"],
    async () => {
      try {
        const smartContractsResponse = await api.get(
          `/dapp/${dappId}/smart-contract`
        );
        if (!smartContractsResponse.data?.data?.smartContracts) return [];

        const smartContractsList: SmartContract[] =
          smartContractsResponse.data?.data?.smartContracts;
        return smartContractsList;
      } catch (error: any) {
        if (error?.response?.status === 404) {
          return [];
        } else {
          throw error;
        }
      }
    },
    {
      ...options,
    }
  );
}
