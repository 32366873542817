import Alert from "components/Alert";
import ExitActionBarButton from "components/buttons/ExitActionBarButton";
import PrimaryButton from "components/buttons/PrimaryButton";
import Select, { Option } from "components/inputs/Select";
import TextInput from "components/inputs/TextInput";
import useMetaTxLimitMutation from "hooks/mutations/metaTxLimitMutation";
import * as React from "react";

const MetaTxsLimit: React.FC<{ dappId: string; userId: string }> = ({
  dappId,
  userId,
}) => {
  const [error, setError] = React.useState("");

  const types = [
    { title: "Native Token", id: 0 },
    { title: "No of gasless tx", id: 1 },
  ];
  const targets = [
    { title: "Per dApp", type: "dapp" },
    { title: "Per User", type: "user" },
    { title: "Per API", type: "api" },
  ];

  const [value, setValue] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [selectedType, setSelectedType] = React.useState<Option>(types[1]);
  const [selectedTarget, setSelectedTarget] = React.useState<Option>(
    targets[0]
  );

  const metaTxsLimitMutation = useMetaTxLimitMutation();

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-6">
          <h1 className="text-xm font-bold">Meta Transaction Limit</h1>
          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Type</label>
            <Select
              options={types}
              selected={selectedType}
              onChange={(newSelectedType) => setSelectedType(newSelectedType)}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Value</label>
            <TextInput
              type="number"
              placeholder="10"
              value={value}
              onKeyUp={(e: any) => {
                if (e.target.valueAsNumber < 0) setValue("0");
              }}
              onBlur={(e: any) => {
                if (e.target.valueAsNumber < 0) setValue("0");
              }}
              onChange={(e: any) => setValue(e.target.valueAsNumber)}
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">Target limit</label>
            <Select
              options={targets}
              selected={selectedTarget}
              onChange={(newSelectedTarget) =>
                setSelectedTarget(newSelectedTarget)
              }
            />
          </div>

          <div className="flex flex-col gap-1">
            <label className="text-base font-medium">
              Duration
              <span className="text-xs text-foreground-light ml-1">
                (in days)
              </span>
            </label>
            <TextInput
              onKeyUp={(e: any) => {
                if (e.target.valueAsNumber < 1) setDuration("1");
              }}
              onBlur={(e: any) => {
                if (e.target.valueAsNumber < 1) setDuration("1");
              }}
              type="number"
              value={duration}
              onChange={(e: any) => setDuration(e.target.valueAsNumber)}
            />
          </div>
          {error && <Alert color="error" message={error} />}
          {metaTxsLimitMutation.isError && (
            <Alert
              color="error"
              message={(metaTxsLimitMutation.error as Error).message}
            />
          )}
          {metaTxsLimitMutation.isSuccess && (
            <Alert color="success" message="Updated Successfully" />
          )}

          <PrimaryButton
            className="py-3"
            onClick={() => {
              setError("");
              if (!(Number(duration) > 0 && Number(value) > 0)) {
                setError("Invalid input");
                return;
              }
              // console.log("Saving Meta Txs Limit 💾");
              metaTxsLimitMutation.mutate({
                userId: userId!,
                dappId: dappId!,
                entityType: selectedTarget.type,
                durationValue: Number(duration),
                durationUnit: "day",
                value: Number(value),
                limitDurationInMs: 24 * 60 * 60 * 1000 * Number(duration),
                limitStartTime: Date.now(),
                type: selectedType.id,
                limitStatus: true,
                toggle: false,
              });
            }}
          >
            Save
          </PrimaryButton>
        </div>

        <ExitActionBarButton />
      </div>
    </>
  );
};

export default MetaTxsLimit;
