import React, { useState } from "react";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";

import TextInput from "components/inputs/TextInput";
import PrimaryButton from "components/buttons/PrimaryButton";
// import GithubButton from "components/buttons/social/GithubButton";
// import GoogleButton from "components/buttons/social/GoogleButton";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "context/Auth";
import Alert from "components/Alert";

interface ISignUpProps {}

const SignUp: React.FunctionComponent<ISignUpProps> = (props) => {
  const navigate = useNavigate();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <main className="flex flex-grow justify-center">
      <form className="flex flex-col max-w-sm flex-grow mx-3 pt-36 gap-5">
        <img src={BiconomyWordmark} className="w-[50%] mx-auto mb-14" alt="" />
        <div className="flex justify-between items-baseline">
          <h1 className="font-semibold text-lg">Sign Up</h1>
          <span className="text-sm text-foreground-light font-normal flex gap-1">
            Existing user?
            <Link to="../signin">
              <span className="text-biconomy-orange">Sign in now</span>
            </Link>
          </span>
        </div>
        <TextInput
          placeholder="Email"
          className="w-full"
          type="email"
          registry={register("email", {
            required: "Please enter your email address",
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Invalid email address",
            },
          })}
        />
        <TextInput
          placeholder="Password"
          className="w-full"
          type="password"
          registry={register("password", { required: true })}
        />
        <TextInput
          placeholder="Organisation Name"
          className="w-full"
          type="text"
          registry={register("orgname", { required: true })}
        />

        {errors.email && <Alert color="error" message={errors.email.message} />}
        {errors.password && <Alert color="error" message="Password is empty" />}
        {errors.orgname && (
          <Alert color="error" message="Organisation Name is empty" />
        )}
        {error && <Alert color="error" message={error} />}

        <PrimaryButton
          className="w-full py-3.5"
          onClick={handleSubmit(async (data) => {
            setError("");
            try {
              await signup(data.email, data.password);
              navigate("/onboarding", { replace: true });
            } catch (err: any) {
              console.error("signUp error", err);
              setError(err.response.data.error || err.message);
            }
          })}
          type="submit"
        >
          Create Account
        </PrimaryButton>
        {/* <div className="flex gap-3 items-center py-2">
          <div className="flex-grow h-0 border border-light-border"></div>
          <span className="text-foreground-dark">Or</span>
          <div className="flex-grow h-0 border border-light-border"></div>
        </div>
        <GoogleButton>
          <span className="py-1.5 mx-auto">Sign up with Google</span>
        </GoogleButton>
        <GithubButton href="">
          <span className="py-1.5 mx-auto">Sign up with Github</span>
        </GithubButton> */}
      </form>
    </main>
  );
};

export default SignUp;
