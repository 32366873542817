import React from "react";
import { AlertIcon } from "assets/icons/Icons";
import { twMerge } from "tailwind-merge";

export interface PrimaryButtonProps {
  className?: string;
  color: "error" | "warning" | "success" | "info";
  message?: string;
}

const colorMap = {
  error: "#AE2443",
  warning: "#EF9400",
  success: "#3FBF62",
  info: "#016ADE",
};

export const Alert: React.FC<PrimaryButtonProps> = ({
  children,
  className,
  color,
  message,
  ...props
}) => {
  return (
    <div
      className={twMerge(
        "p-3 items-center leading-none lg:rounded-full flex lg:inline-flex",
        className
      )}
      style={{ backgroundColor: `${colorMap[color]}1A` }}
      role="alert"
    >
      <AlertIcon color={colorMap[color]} />
      {message && (
        <span className="font-thin text-sm mr-2 flex-auto">{message}</span>
      )}
      {children}
    </div>
  );
};

export default Alert;
