import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { ethers } from "ethers";
import PrimaryOutlineButton from "components/buttons/PrimaryOutlineButton";
import WhitelistNFT from "../components/conditional/WhitelistNFT";
import WhitelistToken from "../components/conditional/WhitelistToken";
import { allowedNetworks } from "utils/allSupportedNetwork";
import Alert from "components/Alert";
import useConditionalMutation from "hooks/mutations/conditionalMutation";
import useConditionalQuery, {
  ConditionalQueryAPI,
} from "hooks/useConditionalQuery";

interface IDappAPIsProps {}

type nftArrayDataType = {
  id: string;
  address: string;
  networkId: string;
};

type tokenArrayDataType = {
  id: string;
  address: string;
  networkId: string;
  tokenLimit: string;
};

const DappAPIEdit: React.FC<IDappAPIsProps> = () => {
  // fetch and update meta api list to state
  const param = useParams();
  const conditionalQuery = useConditionalQuery(param.dappId!, param.apiId!);

  // Whitelist Nft State and Handlers
  const [nftArr, setNftArr] = useState<nftArrayDataType[]>([]);
  const addHandelerNFT = () => {
    const newId = nanoid();
    if (nftArr.length < 5) {
      setNftArr([
        ...nftArr,
        {
          id: newId,
          address: "",
          networkId: allowedNetworks[0].id,
        },
      ]);
    }
  };
  const updateNetworkIdNFT = (id: string, networkId: string) => {
    setNftArr(
      nftArr.map((nft) => {
        if (nft.id === id) {
          return { ...nft, networkId };
        }
        return nft;
      })
    );
  };
  const updateContractAddressNFT = (id: string, address: string) => {
    setNftArr(
      nftArr.map((nft) => {
        if (nft.id === id) {
          return { ...nft, address };
        }
        return nft;
      })
    );
  };
  const deleteHandelerNFT = (removeId: string) => {
    if (nftArr.length > 0)
      setNftArr(
        nftArr.filter((nft) => {
          if (nft.id === removeId) {
            return false;
          }
          return true;
        })
      );
  };

  // Whitelist Token State and Handlers
  const [tokenArr, setTokenArr] = useState<tokenArrayDataType[]>([]);
  const addHandelerToken = () => {
    const newId = nanoid();
    if (tokenArr.length < 5) {
      setTokenArr([
        ...tokenArr,
        {
          id: newId,
          address: "",
          networkId: allowedNetworks[0].id,
          tokenLimit: "0",
        },
      ]);
    }
  };
  const updateNetworkIdToken = (id: string, networkId: string) => {
    setTokenArr(
      tokenArr.map((nft) => {
        if (nft.id === id) {
          return { ...nft, networkId };
        }
        return nft;
      })
    );
  };
  const updateContractAddressToken = (id: string, address: string) => {
    setTokenArr(
      tokenArr.map((nft) => {
        if (nft.id === id) {
          return { ...nft, address };
        }
        return nft;
      })
    );
  };
  const updateTokenLimit = (id: string, tokenLimit: string) => {
    setTokenArr(
      tokenArr.map((nft) => {
        if (nft.id === id) {
          return { ...nft, tokenLimit };
        }
        return nft;
      })
    );
  };
  const deleteHandelerToken = (removeId: string) => {
    if (tokenArr.length > 0)
      setTokenArr(
        tokenArr.filter((nft) => {
          if (nft.id === removeId) {
            return false;
          }
          return true;
        })
      );
  };

  useEffect(() => {
    if (conditionalQuery.data) {
      const newNftArr: nftArrayDataType[] = [];
      const newTokenArr: tokenArrayDataType[] = [];
      for (let i = 0; i < conditionalQuery.data.length; ++i) {
        const newId = nanoid();
        if (conditionalQuery.data[i][0].type === "nft") {
          newNftArr.push({
            id: newId,
            address: conditionalQuery.data[i][0].params.address,
            networkId: conditionalQuery.data[i][0].params.networkId,
          });
        } else {
          newTokenArr.push({
            id: newId,
            address: conditionalQuery.data[i][0].params.address,
            networkId: conditionalQuery.data[i][0].params.networkId,
            tokenLimit: conditionalQuery.data[i][0].token_limit!,
          });
        }
      }
      setNftArr(newNftArr);
      setTokenArr(newTokenArr);
    }
  }, [conditionalQuery.data, param.apiId, param.dappId]);

  // final form submit
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const conditionalMutation = useConditionalMutation();

  const handleSave = async (e: any) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    if (nftArr.length === 0 && tokenArr.length === 0) {
      if (conditionalQuery.data && conditionalQuery.data.length === 0) {
        return;
      }
      await conditionalMutation.mutateAsync({
        apiId: param.apiId!,
        dappId: param.dappId!,
        conditions: [],
      });
      setSuccess("Successfully updated conditions");
      return;
    }
    // check for valid fields
    for (let i = 0; i < nftArr.length; i++) {
      if (nftArr[i].address === "" || nftArr[i].networkId === "") {
        setError("Please fill all addresses in NFT list");
        return;
      }
      if (!ethers.utils.isAddress(nftArr[i].address)) {
        setError(`Invalid contract address ${nftArr[i].address}`);
        return;
      }
    }
    for (let i = 0; i < tokenArr.length; i++) {
      console.log(tokenArr[i].tokenLimit);
      if (tokenArr[i].address === "") {
        setError("Please fill all addresses in Token List");
        return;
      }
      if (Number(tokenArr[i].tokenLimit) <= 0 || !tokenArr[i].tokenLimit) {
        setError("Please enter more than 0 token qty in Token List");
        return;
      }
      if (!ethers.utils.isAddress(tokenArr[i].address)) {
        setError(`Invalid contract address ${tokenArr[i].address}`);
        return;
      }
    }
    // check for duplicates
    for (let i = 0; i < nftArr.length; ++i) {
      for (let j = i + 1; j < nftArr.length; ++j) {
        if (
          nftArr[i].address === nftArr[j].address &&
          nftArr[i].networkId === nftArr[j].networkId
        ) {
          setError("Duplicate Entries in NFT List");
          return;
        }
      }
    }
    for (let i = 0; i < tokenArr.length; ++i) {
      for (let j = i + 1; j < tokenArr.length; ++j) {
        if (
          tokenArr[i].address === tokenArr[j].address &&
          tokenArr[i].networkId === tokenArr[j].networkId
        ) {
          setError("Duplicate Entries in Token List");
          return;
        }
      }
    }
    if (!param.apiId || !param.dappId) {
      setError("Invalid API Id");
      return;
    }
    try {
      let conditionalData: ConditionalQueryAPI[] = [];
      for (let i = 0; i < nftArr.length; i++) {
        conditionalData.push([
          {
            type: "nft",
            params: {
              networkId: nftArr[i].networkId,
              address: nftArr[i].address,
            },
          },
        ]);
      }
      for (let i = 0; i < tokenArr.length; i++) {
        conditionalData.push([
          {
            type: "token_limit",
            params: {
              networkId: tokenArr[i].networkId,
              address: tokenArr[i].address,
            },
            token_limit: tokenArr[i].tokenLimit,
          },
        ]);
      }
      // console.log(conditionalData);
      await conditionalMutation.mutateAsync({
        apiId: param.apiId,
        dappId: param.dappId,
        conditions: conditionalData,
      });
      setSuccess("Successfully updated conditions");
    } catch (err: any) {
      setError(err.message);
      console.error(err);
    }
  };

  return (
    <>
      <h1 className="text-2xl text-center tracking-tight ml-2">
        Conditional Whitelisting for Gasless
      </h1>
      <p className="text-sm text-center text-gray-700 mb-2 ml-2">
        Enable Gasless Transactions for your users if either one of the
        conditions is true.
      </p>
      <Alert color="warning" className="mb-4 w-max m-auto" message="">
        <span className="font-thin text-sm flex-auto">
          For these limits to apply make sure you are using SDK version 2.0.33 &
          above
        </span>
      </Alert>
      <div className="relative box-border rounded-lg bg-black/5 p-5">
        <h1 className="text-xl tracking-tight my-2">Whitelist Using NFTs</h1>
        <p className="text-sm my-2 text-gray-700">
          Enable gasless transactions for your users who hold these NFTs.
        </p>
        {nftArr.map((data) => (
          <WhitelistNFT
            nftData={data}
            key={data.id}
            updateNetworkId={updateNetworkIdNFT}
            updateContractAddress={updateContractAddressNFT}
            deleteHandeler={deleteHandelerNFT}
          />
        ))}
        {nftArr.length < 5 && (
          <PrimaryOutlineButton
            className="mt-5 px-5 py-2 w-max text-sm"
            onClick={addHandelerNFT}
          >
            Add NFT Conditions
          </PrimaryOutlineButton>
        )}
      </div>
      <div className="relative box-border rounded-lg bg-black/5 p-5">
        <h1 className="text-xl tracking-tight my-2">Whitelist Using Tokens</h1>
        <p className="text-sm my-2 text-gray-700">
          Enable gasless transactions for your users who hold minimum amount of
          these tokens.
        </p>
        {tokenArr.map((data) => (
          <WhitelistToken
            tokenData={data}
            key={data.id}
            updateNetworkId={updateNetworkIdToken}
            updateContractAddress={updateContractAddressToken}
            updateTokenLimit={updateTokenLimit}
            deleteHandeler={deleteHandelerToken}
          />
        ))}

        {tokenArr.length < 5 && (
          <PrimaryOutlineButton
            className="mt-5 px-5 py-2 w-max text-sm"
            onClick={addHandelerToken}
          >
            Add Token Conditions
          </PrimaryOutlineButton>
        )}
      </div>

      {error && <Alert color="error" message={error} />}
      {success && <Alert color="success" message={success} />}

      <PrimaryOutlineButton
        className="m-auto mt-5 px-5 py-2 w-1/4"
        onClick={handleSave}
      >
        Save Changes
      </PrimaryOutlineButton>
    </>
  );
};

export default DappAPIEdit;
