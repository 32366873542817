import React, { useEffect, useState } from "react";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";
import Alert from "components/Alert";
import PrimaryButton from "components/buttons/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextInput from "components/inputs/TextInput";
import { useForm } from "react-hook-form";
import { useAuth } from "context/Auth";

interface IUpdatePasswordProps {}

const UpdatePassword: React.FC<IUpdatePasswordProps> = () => {
  const navigate = useNavigate();
  const { updateNewPassword } = useAuth();

  // if has update email token
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [navigate, token]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit } = useForm();

  const updatePassword = async (data: any) => {
    setError("");
    setSuccess(false);
    if (data.password !== data.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    if (!token) {
      setError("Invalid token");
      return;
    }
    try {
      setLoading(true);
      await updateNewPassword(token, data.password);
      setLoading(false);
      setSuccess(true);
      navigate("/");
    } catch (err: any) {
      setLoading(false);
      console.error(err);
      setError(err.response.data.error || err.message);
    }
  };

  return (
    <main className="flex flex-grow justify-center">
      <form
        className="flex flex-col max-w-sm flex-grow mx-3 pt-36 gap-5"
        onSubmit={handleSubmit(updatePassword)}
      >
        <img src={BiconomyWordmark} className="w-[50%] mx-auto mb-14" alt="" />
        <h1 className="font-semibold text-lg">Update Password</h1>
        <TextInput
          placeholder="New Password"
          className="w-full"
          type="password"
          registry={register("password", { required: true })}
        />
        <TextInput
          placeholder="Confirm New Password"
          className="w-full"
          type="password"
          registry={register("confirmPassword", { required: true })}
        />

        {error && <Alert color="error" message={error} />}

        <PrimaryButton
          className="w-full py-3.5"
          disabled={loading}
          type="submit"
        >
          Update
        </PrimaryButton>

        {success && (
          <Alert color="success" message="Password updated successfully!" />
        )}
      </form>
    </main>
  );
};

export default UpdatePassword;
