import * as React from "react";

interface ILabelledDataFieldContainerProps {}

const LabelledDataFieldContainer: React.FunctionComponent<
  ILabelledDataFieldContainerProps
> = ({ children }) => {
  return <div className="flex justify-between gap-1">{children}</div>;
};

export default LabelledDataFieldContainer;
