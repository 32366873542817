import React from "react";
import styled from "styled-components";

export interface SVG {
  color: string;
}

const AlertIconSVG = styled.svg`
  margin-right: 10px;
  fill: ${(props) => props.color};
`;

export const AlertIcon: React.FC<SVG> = ({ color }) => (
  <AlertIconSVG
    width="16"
    height="16"
    viewBox="0 0 16 16"
    color={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.69515 0.326165C4.82302 0.198299 4.99644 0.126465 5.17727 0.126465H10.8227C11.0036 0.126465 11.177 0.198299 11.3048 0.326165L15.3003 4.32162C15.4282 4.44949 15.5 4.62291 15.5 4.80374V10.4492C15.5 10.63 15.4282 10.8034 15.3003 10.9313L11.3048 14.9268C11.177 15.0546 11.0036 15.1265 10.8227 15.1265H5.17727C4.99644 15.1265 4.82302 15.0546 4.69515 14.9268L0.6997 10.9313C0.571834 10.8034 0.5 10.63 0.5 10.4492V4.80374C0.5 4.62291 0.571834 4.44949 0.6997 4.32162L4.69515 0.326165ZM5.45969 1.4901L1.86364 5.08616V10.1668L5.45969 13.7628H10.5403L14.1364 10.1668V5.08616L10.5403 1.4901H5.45969Z"
    />
    <path d="M8.68175 4.89923C8.68175 4.52267 8.37649 4.21741 7.99993 4.21741C7.62338 4.21741 7.31812 4.52267 7.31812 4.89923V7.6265C7.31812 8.00306 7.62338 8.30832 7.99993 8.30832C8.37649 8.30832 8.68175 8.00306 8.68175 7.6265V4.89923Z" />
    <path d="M7.99993 9.67195C7.62338 9.67195 7.31812 9.97721 7.31812 10.3538C7.31812 10.7303 7.62338 11.0356 7.99993 11.0356H8.00675C8.38331 11.0356 8.68857 10.7303 8.68857 10.3538C8.68857 9.97721 8.38331 9.67195 8.00675 9.67195H7.99993Z" />
  </AlertIconSVG>
);
