import api from "api/axios";
import { useMutation, useQueryClient } from "react-query";

interface INewSmartContractData {
  contractId: string;
}

export default function useDeleteSmartContractMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ contractId }: INewSmartContractData) => {
      const result = await api.delete(`/smart-contract/${contractId}`);
      return result.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["smartContractsByDappId"]);
      },
    }
  );
}
