import React, { useState } from "react";
import PrimaryButton from "components/buttons/PrimaryButton";
import Select, { Option } from "components/inputs/Select";
import TextInput from "components/inputs/TextInput";
import useCreateDappMutation from "hooks/mutations/createDappMutation";
import { allowedNetworks } from "utils/allSupportedNetwork";
import Alert from "components/Alert";

interface INewDappFormProps {}

const NewDappForm: React.FunctionComponent<INewDappFormProps> = (props) => {
  const [dappName, setDappName] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [selectedNetwork, setSelectedNetwork] = useState<Option>(
    allowedNetworks[0]
  );

  const createDappMutation = useCreateDappMutation();

  return (
    <>
      <div className="flex w-full flex-col gap-6 text-foreground">
        <h1 className="text-xm font-bold">Register A Dapp</h1>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">Dapp Name</label>
          <TextInput
            value={dappName}
            onChange={(e: any) => setDappName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-base font-medium">Select Network</label>
          <Select
            options={allowedNetworks}
            selected={selectedNetwork}
            onChange={(newSelectedNetwork) =>
              setSelectedNetwork(newSelectedNetwork)
            }
          />
        </div>
        {error && <Alert color="error" message={error} />}
        {success && <Alert color="success" message={success} />}
        <PrimaryButton
          disabled={createDappMutation.isLoading}
          className="py-3 mt-4"
          onClick={async () => {
            setError("");
            setSuccess("");
            try {
              await createDappMutation.mutateAsync({
                dappName,
                networkId: selectedNetwork.id,
              });
              setSuccess("Dapp created successfully");
            } catch (err: any) {
              console.error(err);
              if (err.message.startsWith("user email not verified", 0)) {
                setError("Verify email to create dapps.");
                return;
              }
              if (err.message.includes("already exists")) {
                setError("Dapp Name already exists for user.");
                return;
              }
              setError(err.message);
            }
          }}
        >
          Register
        </PrimaryButton>
      </div>
    </>
  );
};

export default NewDappForm;
