import { useQuery } from "react-query";
import api from "api/axios";

export type ConditionalQueryAPI = {
  type: string;
  params: {
    address: string;
    networkId: string;
  };
  token_limit?: string;
}[];

export default function useConditionalQuery<TData = ConditionalQueryAPI[]>(
  dappId: string,
  apiId: string
) {
  return useQuery<ConditionalQueryAPI[], Error, TData>(
    ["metaApiDetailByDappId", apiId],
    async () => {
      try {
        const condtionalResponse = await api.get(
          `/dapp/${dappId}/meta-api/${apiId}`
        );
        if (!condtionalResponse.data?.data?.api) return [];
        const conditionList: ConditionalQueryAPI[] =
          condtionalResponse.data?.data?.api[0].conditions;
        return conditionList;
      } catch (error: any) {
        if (error?.response?.status === 404) {
          return [];
        } else {
          throw error;
        }
      }
    },
    {
      staleTime: 0,
      retry: 3,
    }
  );
}
