import { createContext, useContext, useState } from "react";
import Logo from "assets/branding/logo.svg";
import api from "api/axios";

import { useQueryClient } from "react-query";
import useUserQuery from "hooks/useUserQuery";

interface IAuthContext {
  login: ILogin;
  logout: () => void;
  signup: ISignup;
  gooogleLogin: (googleData: any) => void;
  githubLogin: (githubData: any) => void;
  resetPassword: IResetPassword;
  updateNewPassword: IUpdateNewPassword;
  isAuth: boolean;
}

const AuthContext = createContext<IAuthContext | null>(null);

type LoginResponse = {
  log: string;
  message: string;
  refresh_token: string;
  status: number;
  betaAccess: boolean;
  flag: number;
  code: number;
};

interface ILogin {
  (email: string, password: string): Promise<LoginResponse>;
}
interface ISignup {
  (email: string, password: string): Promise<LoginResponse>;
}
interface IResetPassword {
  (email: string): Promise<any>;
}
interface IUpdateNewPassword {
  (token: string, password: string): Promise<any>;
}

const AuthProvider: React.FC = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const queryClient = useQueryClient();
  const user = useUserQuery();

  const login: ILogin = async (email, password) => {
    const loginResponse = await api.post("/user/login", { email, password });
    queryClient.invalidateQueries("user");
    setIsAuth(true);
    return loginResponse.data as LoginResponse;
  };

  const gooogleLogin = async (googleData: any) => {
    const loginResponse = await api.post("/user/login/google", {
      token: googleData.tokenId,
    });
    queryClient.invalidateQueries("user");
    setIsAuth(true);
    return loginResponse.data as LoginResponse;
  };

  const githubLogin = async (githubData: any) => {
    const loginResponse = await api.post("/user/login/github", {
      githubCode: githubData.code,
    });
    queryClient.invalidateQueries("user");
    setIsAuth(true);
    return loginResponse.data as LoginResponse;
  };

  const logout = async () => {
    try {
      const logoutResponse = await api.get("/user/logout");
      await queryClient.invalidateQueries();
      setIsAuth(false);
      return logoutResponse;
    } catch (err: any) {
      await queryClient.invalidateQueries();
    }
  };

  const signup: ISignup = async (email, password) => {
    const signupResponse = await api.post("/user", { email, password });
    queryClient.invalidateQueries("user");
    return signupResponse.data as LoginResponse;
  };

  const resetPassword: IResetPassword = async (email) => {
    const resetResponse = await api.post("/user/forgot-password", { email });
    queryClient.invalidateQueries("user");
    return resetResponse.data;
  };

  const updateNewPassword: IUpdateNewPassword = async (token, password) => {
    const updateNewResponse = await api.post("/user/new-password", {
      token,
      password,
    });
    queryClient.invalidateQueries("user");
    return updateNewResponse.data;
  };

  if (user.isLoading) {
    return (
      <div className="flex flex-col w-full h-screen justify-center items-center">
        <img
          src={Logo}
          className="animate-bounce h-12 w-auto text-left"
          alt=""
        />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        signup,
        resetPassword,
        updateNewPassword,
        gooogleLogin,
        githubLogin,
        isAuth,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const userContext = useContext(AuthContext);
  if (!userContext) {
    throw new Error("User Context used without provider");
  }
  return userContext;
};
export { AuthProvider, useAuth };
