import React from "react";
import { twMerge } from "tailwind-merge";

interface IDataCardHeadingProps {
  title: React.ReactNode;
  className?: string;
}

const DataCardHeading: React.FunctionComponent<IDataCardHeadingProps> = ({
  title,
  className,
}) => {
  return (
    <span
      className={twMerge("text-biconomy-orange text-2xl truncate", className)}
    >
      {title}
    </span>
  );
};

export default DataCardHeading;
