import React, { useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";

import PrimaryButton from "components/buttons/PrimaryButton";
import Stepper from "./components/Stepper";
import MultiSelect from "components/inputs/MutliSelect";
import TextArea from "components/inputs/TextArea";
import Alert from "components/Alert";

interface IStepProps {
  category: {
    value: string;
    label: string;
  }[];
  setCategory: React.Dispatch<
    React.SetStateAction<
      {
        value: string;
        label: string;
      }[]
    >
  >;
  desc: string;
  setDesc: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<1 | 2 | 3 | 4>>;
}

const Step: React.FunctionComponent<IStepProps> = ({
  category,
  setCategory,
  desc,
  setDesc,
  setStep,
}) => {
  const [error, setError] = useState("");

  const handleNext = (e: any) => {
    e.preventDefault();
    if (category.length === 0 && desc.length === 0) {
      setError("Please select at least one category and provide a description");
      return;
    }
    if (category.length === 0) {
      setError("Please select at least one category");
      return;
    }
    if (desc.length === 0) {
      setError("Please enter a description");
      return;
    }
    try {
      setStep(3);
    } catch (err: any) {
      console.error(err);
      setError(err.message);
    }
  };

  return (
    <>
      <img src={BiconomyWordmark} className="w-[50%] mx-auto" alt="" />
      <Stepper step={2} />
      <div className="flex justify-between items-baseline mb-10">
        <h1 className="font-medium text-2xl mx-auto text-foreground">
          Hey, we're almost done!
        </h1>
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-medium text-foreground">
          Business Categories
        </label>
        <MultiSelect category={category} setCategory={setCategory} />
      </div>
      <div className="flex flex-col gap-2">
        <label className="font-medium text-foreground">Description</label>
        <TextArea onChange={(e: any) => setDesc(e.target.value)} />
      </div>

      {error && <Alert color="error" message={error} />}

      <PrimaryButton className="w-full py-4 mt-2" onClick={handleNext}>
        <HiArrowRight className="w-5 h-auto" />
      </PrimaryButton>
    </>
  );
};

export default Step;
